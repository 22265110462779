'use strict';

class LightControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    getTitle2() {
        if (this.content.displayControlName) {
            return this.control.getName(); // will show the name
        } else {
            return super.getSubtitle(...arguments);
        }
    }

    getSubtitle() {
        if (this.content.displayControlName) {
            return super.getSubtitle(...arguments);
        } else {
            return null;
        }
    }

}

GUI.TableViewV2.Cells.LightControlCard = LightControlCard;
