'use strict';

class AudioZoneControl extends Controls.ControlBase {
    //region Static
    static Template = function () {
        var getHeader = function getHeader(ctrl) {
            var disclosureIcon = ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'disclosure__icon');
            return $('<div class="content__top">' + '<div class="top__cover">' + '</div>' + '<div class="top__center">' + '<div class="center__main center__label text-overflow-ellipsis">' + '<div class="main__text main__label text-overflow-ellipsis">...</div>' + '<div class="main__disclosure">' + disclosureIcon + '</div>' + '</div>' + '<div class="center__sub sub__label center__label  text-overflow-ellipsis">...</div>' + '</div>' + '<div class="top__group top__button">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.BTN_GROUP, "group__icon button__icon") + '</div>' + '<div class="top__button top__button--prev">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.BTN_PREV_ROUND, "button__icon") + '</div>' + '<div class="top__play-button top__button">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PAUSE_FULL, "play-button__pause-icon button__icon") + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PLAY_FULL, "play-button__play-icon button__icon") + ImageBox.getResourceImageWithClasses(Icon.AudioZone.PLAY_FULL, "play-button__offline-icon button__icon") + ImageBox.getResourceImageWithClasses(Icon.CAUTION, "play-button__not-reachable-icon button__icon") + '<div class="play-button__initializing-icon button__icon css-spinner-a"></div>' + '</div>' + '<div class="top__button top__button--next top__button--right">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.BTN_NEXT_ROUND, "button__icon") + '</div>' + '</div>' + '<div class="content__bottom"></div>');
        };

        return {
            getHeader: getHeader
        };
    }(); //endregion Static

    constructor(delegate, dataSource, control, sectionIdx, rowIdx, tableView) {
        Debug.Control.AudioZone.Cell && console.log("AudioZoneControlCell constructor");
        super(...arguments);
    }

    contentForCell() {
        return {
            visuType: Controls.VisuType.CONTROL_CONTENT,
            useBaseHeader: false
        };
    }

    initHeader() {
        Debug.Control.AudioZone.Cell && console.log("AudioZoneControlCell initHeader");
        let prms = [],
            headerContent = AudioZoneControl.Template.getHeader(this.control.name); // required to show/hide the disclosure icon via baseClass

        this.headerElements.disclosureIcon = headerContent.find('.disclosure__icon');
        this.elements = {};
        this.elements.playPauseButton = headerContent.find('.top__play-button');
        this.elements.groupIcon = headerContent.find('.top__group');
        this.elements.mainLabel = headerContent.find('.main__label');
        this.elements.subLabel = headerContent.find('.sub__label');
        this.elements.headerTop = $(headerContent[0]);
        this.elements.coverContainer = headerContent.find('.top__cover');
        this.elements.coverView = new Controls.AudioZoneControl.CoverView({});
        this.elements.coverView.element.addClass("cover__element");
        prms.push(this.appendSubview(this.elements.coverView, this.elements.coverContainer));
        this.elements.pauseIcon = headerContent.find('.play-button__pause-icon').hide();
        this.elements.playIcon = headerContent.find('.play-button__play-icon').hide();
        this.elements.offlineIcon = headerContent.find('.play-button__offline-icon').show();
        this.elements.notRechableIcon = headerContent.find('.play-button__not-reachable-icon').hide();
        this.elements.initializingIcon = headerContent.find('.play-button__initializing-icon').hide();
        this.elements.nextButton = headerContent.find('.top__button--next');
        this.elements.prevButton = headerContent.find('.top__button--prev');
        this.buttons = {};
        this.buttons.playPauseButton = new GUI.LxButton(this, this.elements.playPauseButton[0], Color.BUTTON_GLOW, null, true);
        this.addToHandledSubviews(this.buttons.playPauseButton); // next & prev buttons

        this.buttons.nextButton = new GUI.LxButton(this, this.elements.nextButton[0], Color.BUTTON_GLOW, null, true);
        this.buttons.nextButton.useChildsAsActiveParts("fill");
        this.addToHandledSubviews(this.buttons.nextButton);
        this.buttons.prevButton = new GUI.LxButton(this, this.elements.prevButton[0], Color.BUTTON_GLOW, null, true);
        this.buttons.prevButton.useChildsAsActiveParts("fill");
        this.addToHandledSubviews(this.buttons.prevButton);
        return Q.all(prms).then(() => {
            return headerContent
        });
    }

    getStatusElements() {
        return {}; // return {} -> don't hide anything, but also don't disable the cell!
    }

    viewDidAppear() {
        Debug.Control.AudioZone.Cell && console.log("AudioZoneControlCell viewDidLoad");
        super.viewDidAppear();

        if (!this.singleVolumeControl) {
            var bottomElem = this.element.find('.content__bottom');
            this.singleVolumeControl = new Controls.AudioZoneControl.AudioZoneControlVolumeControl(this.control, 'cell', null, bottomElem);
            this.singleVolumeControl.getElement().addClass('content__bottom');
            this.addToHandledSubviews(this.singleVolumeControl);
        }

        this.buttons.playPauseButton.onButtonTapped = this._handlePlayPauseButton.bind(this);
        this.buttons.nextButton.onButtonTapped = this._onSwipeLeft.bind(this);
        this.buttons.prevButton.onButtonTapped = this._onSwipeRight.bind(this);
        this.swipeHandlers = [];
        var swipeOptions = {
            swipeVelocityX: 0.1
        };
        this.swipeHandlers.push(Hammer(this.elements.headerTop[0], swipeOptions).on('swipeleft', this._onSwipeLeft.bind(this)));
        this.swipeHandlers.push(Hammer(this.elements.headerTop[0], swipeOptions).on('swiperight', this._onSwipeRight.bind(this)));
    }

    viewWillDisappear() {
        super.viewWillDisappear(...arguments);
        this.buttons.playPauseButton.onButtonTapped = null;
        this.buttons.nextButton.onButtonTapped = null;
        this.buttons.prevButton.onButtonTapped = null;

        for (var i = 0; i < this.swipeHandlers.length; i++) {
            this.swipeHandlers[i].dispose();
        }

        this.swipeHandlers = [];
    }

    updateHeader(states) {
        Debug.Control.AudioZone.Cell && console.log("AudioZoneControlCell updateHeader");
        Debug.Control.AudioZone.Cell && console.log(this.control.name, "receivedStates " + JSON.stringify(states));
        this.states = cloneObjectDeep(states);

        this._updateLabels(); // update grouping


        if (this.states.isSynced) {
            this.elements.groupIcon.show();
            this.elements.groupIcon[0].style.fill = this.states.syncColor;
        } else {
            this.elements.groupIcon.hide();
        } // updated rightIcon


        if (this.states.serverReady && this.states.clientReady) {
            this.elements.initializingIcon.hide();
            this.elements.notRechableIcon.hide();
            this.elements.offlineIcon.hide();

            if (this.states.isPlaying) {
                this.elements.playIcon.hide();
                this.elements.pauseIcon.show();
            } else {
                this.elements.playIcon.show();
                this.elements.pauseIcon.hide();
            }
        } else {
            var isInitializing = this.states.clientBooting || this.states.serverState === MediaEnum.ServerState.INITIALIZING;

            if (isInitializing) {
                this.elements.offlineIcon.hide();
                this.elements.notRechableIcon.hide();
                this.elements.initializingIcon.show();
            } else if (this.states.serverState === MediaEnum.ServerState.NOT_REACHABLE) {
                this.elements.notRechableIcon.show();
                this.elements.offlineIcon.hide();
                this.elements.initializingIcon.hide();
            } else {
                this.elements.notRechableIcon.hide();
                this.elements.offlineIcon.show();
                this.elements.initializingIcon.hide();
            }

            this.elements.playIcon.hide();
            this.elements.pauseIcon.hide();
        }

        if (!this.showGroupDetails) {
            this.element.toggleClass('audio-zone-control-cell--not-reachable', this.states.serverState === MediaEnum.ServerState.NOT_REACHABLE);
            this.element.toggleClass('audio-zone-control-cell--client-not-reachable', this.states.clientState === MediaEnum.ClientState.NOT_REACHABLE);
            this.element.toggleClass('audio-zone-control-cell--not-ready', !this.states.serverReady || !this.states.clientReady);
        } else {
            this.element.toggleClass('audio-zone-control-cell--fav-not-reachable', this.states.serverState === MediaEnum.ServerState.NOT_REACHABLE);
            this.element.toggleClass('audio-zone-control-cell--fav-client-not-reachable', this.states.clientState === MediaEnum.ClientState.NOT_REACHABLE);
            this.element.toggleClass('audio-zone-control-cell--fav-not-ready', !this.states.serverReady || !this.states.clientReady);
        } // connection state


        if (!this.states.serverReady || !this.states.clientReady) {
            var data = {};
            data.iconSrc = Icon.AudioZone.SLEEP;
            data.iconColor = Color.STATE_INACTIVE;
            this.elements.coverView.setConfig.call(this.elements.coverView, data);
        } else {
            this.elements.coverView.setConfig.call(this.elements.coverView, this.states);
        }
    }

    /**
     * When the user clicks the cell it is opened & the music server will be started.
     */
    didSelectCell() {
        if (this.states && !this.states.serverReady) {
            this.sendCommand(Commands.AudioZone.SERVER.ON);
        }

        super.didSelectCell(...arguments);
    }

    // ----------------------
    // Handle UI Feedback
    // ----------------------
    _handlePlayPauseButton() {
        if (!this.states.serverReady) {
            if (Feature.AUDIO_ZONE__WAKE_AND_PLAY) {
                // starts the server & sends play right after it is awake
                this.sendCommand(Commands.AudioZone.CLIENT.PLAY);
            } else {
                this.sendCommand(Commands.AudioZone.SERVER.ON);
            }
        } else if (this.states.clientReady) {
            if (this.states.isPlaying) {
                this._sendAudioCmdToMediaServer(MediaEnum.AudioCommands.PAUSE);
            } else {
                this._sendAudioCmdToMediaServer(MediaEnum.AudioCommands.PLAY);
            }
        } else {
            this.sendCommand(Commands.AudioZone.CLIENT.ON);
        }
    }

    _onSwipeLeft() {
        if (Feature.MULTI_MUSIC_SERVER) {
            this.sendCommand(Commands.AudioZone.Queue.NEXT);
        } else {
            this._sendAudioCmdToMediaServer(MediaEnum.AudioCommands.QUEUE.NEXT);
        }
    }

    _onSwipeRight() {
        if (Feature.MULTI_MUSIC_SERVER) {
            this.sendCommand(Commands.AudioZone.Queue.PREV);
        } else {
            this._sendAudioCmdToMediaServer(MediaEnum.AudioCommands.QUEUE.PREV);
        }
    }

    // ----------------------
    // Communication
    // ----------------------
    _sendAudioCmdToMediaServer(cmd) {
        if (!this.states.serverReady) {
            Debug.Control.AudioZone.Cell && console.info("AudioZoneControlCell", "Server not ready, wake for cmd: " + cmd);
            this.sendCommand(Commands.AudioZone.SERVER.ON);
        }

        if (Feature.MULTI_MUSIC_SERVER) {
            // in the cell, the connection will never be ready with this feature
            this.sendCommand(cmd);
        } else {
            MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: cmd
            });
        }
    }

    // ----------------------
    // Update the UI
    // ----------------------
    _updateLabels() {
        Debug.Control.AudioZone.Cell && console.log("AudioZoneControlCell _updateLabels");
        var mainText = null;
        var subText = null;

        if (this.states.texts.connectivityText) {
            mainText = this.control.name;
            subText = this.states.texts.connectivityText;
        } else if (!this.states.hasOwnProperty('texts')) {
            mainText = "";
            subText = "";
        } else {
            mainText = this.states.texts.mainText;
            subText = this.states.texts.subText;
        }

        var nrOfEqualControls = this.nrOfEqualCtrls();

        if ((nrOfEqualControls > 1 || this.showGroupDetails) && !this.states.texts.connectivityText) {
            // there is a separate subtext - move the current subtext into the mainText
            var newMainText = mainText && mainText !== '' ? mainText : '';

            if (subText && subText !== '') {
                if (newMainText && newMainText !== '') {
                    newMainText += " - ";
                }

                newMainText += subText;
            }

            mainText = newMainText;
            subText = "";
        } // we've got more than one equal control, the name matters, show it


        if (nrOfEqualControls > 1 && !this.states.texts.connectivityText) {
            subText += this.control.name;
        } // in the favorite list we need to show the groupDetail of the control too


        if (this.showGroupDetails) {
            // e.g. when the zone is off, this is being shown as subtext, move to mainText.
            if (this.states.texts.connectivityText) {
                mainText += " - " + this.states.texts.connectivityText;
                subText = "";
            }

            if (subText !== "") {
                subText += SEPARATOR_SYMBOL;
            }

            subText += this.control.groupDetail;
        }

        this.elements.mainLabel.text(mainText);
        this.elements.subLabel.text(subText);
    }

}

Controls.AudioZoneControl = AudioZoneControl;
/**
 * Theses "old" screenstates have to be present here, as they are used e.g. in the mediaServerComponent where
 * require.js isn't in use and therefore MediaEnums isn't used either.
 * @type {{DETAILS: string, ADD_MEDIA: string, SERVICE_LOGIN: string, CONTEXT_MENU: string}}
 */

Controls.AudioZoneControl.ScreenState = {
    DETAILS: 'Controls.AudioZoneControl.AudioZoneControlDetails',
    ADD_FAVORITES: 'Controls.AudioZoneControl.AddMediaViewControllerZoneFavorite',
    ADD_MEDIA_TO_PLAYLIST: 'Controls.AudioZoneControl.AddMediaViewControllerPlaylist',
    ADD_MEDIA_TO_PLAYLIST_PICKER: 'Controls.AudioZoneControl.AddMediaViewControllerPlaylistPicker',
    ADD_MEDIA_TO_PLAYLIST_SPOTIFY: 'Controls.AudioZoneControl.AddMediaViewControllerPlaylistSpotify',
    ADD_MEDIA: "Controls.AudioZoneControl.AddMediaScreen",
    EDIT_MEDIA: "Controls.AudioZoneControl.EditMediaScreen",
    SERVICE_LOGIN: 'Controls.AudioZoneControl.AudioZoneSettingsServiceLoginScreen',
    CONTEXT_MENU: 'Controls.AudioZoneControl.AudioZoneContextMenu'
};
