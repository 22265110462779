'use strict';

class AudioZoneV2ControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        this.addHandlers();
    }

    viewWillDisappear() {
        super.viewWillDisappear(...arguments);
        this.removeHandlers();
    }

    startEditMode() {
        this.removeHandlers();
    }

    stopEditMode() {
        this.addHandlers();
    }

    removeHandlers() {
        for (var i = 0; i < this.swipeHandlers.length; i++) {
            this.swipeHandlers[i].dispose();
        }

        this.swipeHandlers = [];
    }

    addHandlers() {
        this.swipeHandlers = [];
        var swipeOptions = {
            swipeVelocityX: 0.1
        };
        this.swipeHandlers.push(Hammer(this.element[0], swipeOptions).on('swipeleft', this._onSwipeLeft.bind(this)));
        this.swipeHandlers.push(Hammer(this.element[0], swipeOptions).on('swiperight', this._onSwipeRight.bind(this)));
    }

    allStatesReceived(allStatesArrived) {
        allStatesArrived = true;
        super.allStatesReceived(allStatesArrived);
    }

    /**
     * return all state id's which are needed for the cell
     * @returns {[string,..]}
     */
    getStateIDs() {
        var ids = super.getStateIDs(...arguments); // the stateText is used in getStateDependentTitle2, if not covered here, a change might be missed.

        ids.push("stateText");
        return ids;
    }

    getTitle2() {
        var text = this._getStateDependentTitle2();

        if (this.content.displayControlName || !text || this.control.getStates().universalIsLocked) {
            text = this.control.getName(); // will show the name
        }

        return text || this._getStateDependentTitle2();
    }

    _getStateDependentTitle2() {
        var text;

        if (!this.control.isGrouped()) {
            text = this.control.getStates().stateText;
        }

        return text;
    }

    getSubtitle() {
        var subtitle = super.getSubtitle(...arguments),
            stateDepText = this._getStateDependentTitle2();

        if (this.states.isLocked) {
            return _("operation-blocked");
        } else if (this.control.isConfigured() && this.content.displayControlName && stateDepText) {
            if (subtitle) {
                if (this.control.getStates().shouldShowVolume) {
                    return subtitle;
                } else {
                    return subtitle + SEPARATOR_SYMBOL + stateDepText;
                }
            } else {
                return stateDepText;
            }
        } else {
            return subtitle;
        }
    }

    _onSwipeLeft() {
        this.sendCommand(Commands.AudioZoneV2.Queue.NEXT);
    }

    _onSwipeRight() {
        this.sendCommand(Commands.AudioZoneV2.Queue.PREV);
    }

}

GUI.TableViewV2.Cells.AudioZoneV2ControlCard = AudioZoneV2ControlCard;
