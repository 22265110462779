'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneV2Control = Controls.AudioZoneV2Control || {};
    Controls.AudioZoneV2Control.PlayPauseTheme = {
        A: "DarkOnBrightGround",
        B: "BrightOnDarkGround",
        MediaClient: "MediaClient",
        THIN: "ThinLines"
    };

    class PlayPauseButton extends GUI.View {
        //region Static
        static Template = function () {
            var getButtons = function getButtons(theme) {
                var playImage = Icon.AudioZone.PLAY,
                    pauseImage = Icon.AudioZone.PAUSE;

                switch (theme) {
                    case Controls.AudioZoneV2Control.PlayPauseTheme.A:
                        playImage = Icon.AudioZone.PLAY_FULL;
                        pauseImage = Icon.AudioZone.PAUSE_FULL;
                        break;

                    case Controls.AudioZoneV2Control.PlayPauseTheme.B:
                        playImage = Icon.AudioZone.PLAY_FULL_B;
                        pauseImage = Icon.AudioZone.PAUSE_FULL_B;
                        break;

                    case Controls.AudioZoneV2Control.PlayPauseTheme.MediaClient:
                        playImage = Icon.MediaClient.PLAY;
                        pauseImage = Icon.MediaClient.PAUSE;
                        break;
                }

                return ImageBox.getResourceImageWithClasses(playImage, "play-pause__play play-pause__icon") + ImageBox.getResourceImageWithClasses(pauseImage, "play-pause__pause play-pause__icon") + '<div class="play-pause__initializing play-pause__icon css-spinner-b"></div>';
            };

            return {
                getButtons: getButtons
            };
        }(); //endregion Static

        constructor(control, theme) {
            super($('<div class="audio-zone-play-pause-button clickable"></div>'));
            applyMixins(this, StateHandler.Mixin);
            this.control = control;
            this.theme = theme;

            switch (theme) {
                case Controls.AudioZoneV2Control.PlayPauseTheme.B:
                    this.element.addClass("audio-zone-play-pause-button--b");
                    break;

                case Controls.AudioZoneV2Control.PlayPauseTheme.THIN:
                    this.element.addClass("audio-zone-play-pause-button--thin");
                    break;

                case Controls.AudioZoneV2Control.PlayPauseTheme.MediaClient:
                    this.inControlCell = true;
                    this.element.addClass("audio-zone-play-pause-button--a");
                    break;

                default:
                    this.theme = Controls.AudioZoneV2Control.PlayPauseTheme.A;
                    this.inControlCell = true;
                    this.element.addClass("audio-zone-play-pause-button--a");
                    break;
            }

            this.states = {};
        }

        viewDidLoad() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewDidLoad");
            return super.viewDidLoad(...arguments).then(function () {
                this.element.append(PlayPauseButton.Template.getButtons(this.theme));
                this.elements = {}; // Control

                this.elements.playButtonIcon = this.element.find('.play-pause__play').hide();
                this.elements.pauseButtonIcon = this.element.find('.play-pause__pause').hide();
                this.elements.initializingIcon = this.element.find('.play-pause__initializing'); // ---------------------
                //    active controls
                // ---------------------

                this.buttons = {
                    playPauseButton: new GUI.LxButton(this, this.element[0], Color.BUTTON_GLOW)
                };
                this.buttons.playPauseButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.buttons.playPauseButton);
            }.bind(this));
        }

        viewWillAppear() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewWillAppear");
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates();

                this.buttons.playPauseButton.onButtonTapped = this._handlePlayPauseButton.bind(this);
            }.bind(this));
        }

        viewDidAppear() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewDidAppear");
            return super.viewDidAppear(...arguments);
        }

        viewWillDisappear() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewWillDisappear");

            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        // --------------------------------------------------------
        // --------------------------------------------------------
        // State Handling
        // --------------------------------------------------------
        // --------------------------------------------------------
        receivedStates(states) {
            // PlayState
            if (this.element === null) {
                console.error(this.viewId, " is still receiving updates, even tough it's no longer alive!");
                return;
            }

            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " _receivedStates");
            this.states = states;

            if (this.states.serverReady) {
                this.element.toggleClass('active', this.states.isPlaying);
                Debug.Control.AudioZone.PlayPauseButton && console.log("             server and client ready");

                if (this.states.isPlaying) {
                    this.elements.pauseButtonIcon.show();
                    this.elements.playButtonIcon.hide();
                    this.elements.initializingIcon.hide();
                } else {
                    this.elements.playButtonIcon.show();
                    this.elements.initializingIcon.hide();
                    this.elements.pauseButtonIcon.hide();
                }
            } else {
                this.element.toggleClass('active', false);
                Debug.Control.AudioZone.PlayPauseButton && console.log("             off");
                Debug.Control.AudioZone.PlayPauseButton && console.log(this.states);
                this.elements.playButtonIcon.show();
                this.elements.initializingIcon.hide();
                this.elements.pauseButtonIcon.hide();
            }
        }

        // --------------------------------------------------------
        // --------------------------------------------------------
        // Connection Handling
        // --------------------------------------------------------
        // --------------------------------------------------------
        sendMusicServerCommand(cmd) {
            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: cmd
            }); // don't pass in a success-fn, no event command does require one.
        }

        sendMiniserverCommand(cmd) {
            var promise = SandboxComponent.sendCommand(this, this.control.uuidAction, cmd, null, this.control.isSecured);
            promise.then(function success() {//console.log("successfully sent command: " , cmd);
            }, function error(e) {
                console.error(e);
                this.receivedStates(this.states);
            }.bind(this), function notify(i) {
                console.info(i);
            });
            return promise;
        }

        // ------------------------------------------------------------------------------
        // ------------------------------------------------------------------------------
        //                UI Interaction
        // ------------------------------------------------------------------------------
        _handlePlayPauseButton() {
            if (!this.states) {
                return;
            }

            if (!this.states.serverReady) {
                // starts the server & sends play right after it is awake
                this.sendMiniserverCommand(Commands.AudioZone.CLIENT.PLAY);
            } else {
                if (this.states.isPlaying) {
                    this.sendMiniserverCommand(MusicServerEnum.AudioCommands.PAUSE);
                } else {
                    this.sendMiniserverCommand(MusicServerEnum.AudioCommands.PLAY);
                }
            }
        }

    }

    Controls.AudioZoneV2Control.PlayPauseButton = PlayPauseButton;
    return Controls;
}(window.Controls || {});
