'use strict';

var GUI = function (GUI) {
    class AudioZoneV2ControlStripOverlay extends GUI.View {
        //region Static
        static Template = class {
            static getOverlay() {
                return $("<div class='audio-zone-v2-control-strip-overlay'>" + "   " + "<div class='audio-zone-v2-control-strip-overlay__content'></div>" + "   " + "<div class='control-strip-placeholder'></div>" + "</div>");
            }
            static getHeader() {
                return $("<div class='content__title-bar'>" + "   <div class='title-bar__text'></div>" + "</div>");
            }
            static getMainButton() {
                return $("<div class='content__main-button'/>");
            }
        }; //endregion Static

        constructor(control, dismissFn, forEco) {
            super(AudioZoneV2ControlStripOverlay.Template.getOverlay());
            applyMixins(this, StateHandler.Mixin);
            this.control = control;
            this.dismissFn = dismissFn;
            this.forEco = !!forEco;

            if (this.forEco) {
                this.element.addClass("audio-zone-v2-control-strip-overlay--for-eco");
            }

            this.updateAction = null;
        }

        viewDidLoad() {
            Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "viewDidLoad");
            this._boundRemove = this._animateOut.bind(this);
            return super.viewDidLoad(...arguments).then(function () {
                GUI.animationHandler.setCssAttr(this.element, "opacity", "0");
                this.elements = {
                    content: this.element.find(".audio-zone-v2-control-strip-overlay__content")
                };
                this.groupVolumeView = new GUI.AudioZoneV2GroupVolumeView(this.control, this._showGroupingView.bind(this), this.forEco);
                this.appendSubview(this.groupVolumeView, this.elements.content);
                this.groupVolumeView.getElement().addClass("content__view");
                this.groupingView = new GUI.AudioZoneV2GroupingView(this.control, this._showGroupVolumeView.bind(this), this.dismissFn, this.forEco);
                this.appendSubview(this.groupingView, this.elements.content);
                this.hideSubview(this.groupingView);
                this.groupingView.getElement().addClass("content__view");
                this._showingVolume = true;
                this.controlStrip = new GUI.AudioZoneV2ControlStrip(this.control, {
                    hudOpen: true,
                    dismissFn: this.dismissFn,
                    isInAmbientMode: this.isInAmbientMode()
                });
                this.appendSubview(this.controlStrip, this.element.find(".control-strip-placeholder"));
                this.bgButton = new GUI.LxButton(this, this.element[0]);
                this.addToHandledSubviews(this.bgButton);

                this.bgButton.onButtonTapped = function (button, event) {
                    // Handle a long press/click on the tableViewCells
                    // The context menu should not get hidden, so we only hide it if the events srcElement
                    // is not part of the tableView element
                    if (!this.elements.content.find(event.srcElement)[0]) {
                        this._animateOut();
                    }
                }.bind(this);
            }.bind(this));
        }

        _allowGroupingView() {
            var allow = this.control.audioserverComp.supportsDynamicGroups() && (this.control.getStates().isSynced || this.control.isFixedGroup);
            Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_allowGroupingView");
            return allow;
        }

        getStateIDs() {
            return [MusicServerEnum.Event.SYNCED_ZONES, MusicServerEnum.Event.MASTER_VOLUME, "isSynced", "syncColor", "syncedzones"];
        }

        viewWillAppear() {
            return super.viewWillAppear(...arguments).then(function (res) {
                this._animateIn();

                return res;
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDs());

                NavigationComp.registerForBackNavigation(this._boundRemove);
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates();

            NavigationComp.unregisterFromBackNavigation(this._boundRemove);
            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "receivedStates");

            if (!states.canPlayMusic) {
                this._animateOut();

                return;
            }

            if (this.control.getStates().isSynced || this.control.isFixedGroup) {
                Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "   synced or fixedGroup -> nothing to do"); // nothing to do.
            } else {
                Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "   neither synced nor fixedGroup -> show groupingView");

                this._showGroupingView();
            }
        }

        _animateIn() {
            try {
                HapticFeedback();

                if (this.element.css("opacity") === "0") {
                    this._animate(this.element, {
                        opacity: [1, 0]
                    });
                }
            } catch (ex) {
                console.error(this.viewId, "_animateIn failed with exception: " + JSON.stringify(ex.name) + ", " + JSON.stringify(ex.message));
                console.error(this.viewId, ex.toString());
            }
        }

        _animateOut() {
            try {
                this._animate(this.element, {
                    opacity: [0, 1]
                }, function () {
                    this.dismissFn ? this.dismissFn() : this.ViewController.removeSubview(this);
                }.bind(this));
            } catch (ex) {
                console.error(this.viewId, "_animateOut failed with exception: " + JSON.stringify(ex.name) + ", " + JSON.stringify(ex.message));
                console.error(this.viewId, ex.toString());
            }
        }

        _animate(element, options, complete) {
            if (!SandboxComponent.isInForeground() || document.hidden) { // animation would be delayed, messing with the lifecycles, if hidden - no need to animate.
                console.warn(this.viewId, "_animate: bypass animations, not in foreground!");
                complete && complete();
                return;
            }
            options.translateZ = 0; // Force HA by animating a 3D property

            element.velocity(options, {
                duration: 200,
                easing: "ease-out-expo",
                complete: complete
            });
        }

        _showGroupingView() {
            if (this.control.audioserverComp.supportsDynamicGroups()) {
                Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_showGroupingView");

                this._changeViews(this.groupingView, this.groupVolumeView);
            } else {
                Debug.Control.AudioZone.ControlStripOverlay && console.warn(this.viewId, "_showGroupingView - !NOT SUPPORTED!");
            }
        }

        _showGroupVolumeView() {
            Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_showGroupVolumeView");

            this._changeViews(this.groupVolumeView, this.groupingView);
        }

        _changeViews(toShow, toHide) {
            if (this._changeViewPromise && this._changeViewPromise.isPending()) {
                Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_changeViews: ENQUEUE to " + toShow.viewId); // currently exchanging views, store for later.

                this._nextViewAction = this._showGroupVolumeView.bind(this, toShow, toHide);
            } else if (this._visibleView === toShow) {
                // nothing to do.
                return Q.resolve();
            } else {
                Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_changeViews: " + toShow.viewId);
                this._changeViewPromise = Q.all([this.hideSubview(toHide), this.showSubview(toShow)]).then(function () {
                    Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_changeViews: " + toShow.viewId + " >> PASSED");
                    this._visibleView = toShow;

                    if (this._nextViewAction) {
                        Debug.Control.AudioZone.ControlStripOverlay && console.log(this.viewId, "_changeViews: " + toShow.viewId + " --> now on to the next");
                        var action = this._nextViewAction;
                        this._nextViewAction = null;
                        return action();
                    }
                }.bind(this));
                return this._changeViewPromise;
            }
        }

    }

    GUI.AudioZoneV2ControlStripOverlay = AudioZoneV2ControlStripOverlay;
    return GUI;
}(GUI || {});
