'use strict';

class AlarmClockControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    getStateIDs() {
        return super.getStateIDs(...arguments).concat("isRinging");
    }

    receivedStates(states) {
        super.receivedStates(...arguments);
        this.elements.titleIcon[0].classList.toggle("shake", states.isRinging);
        this.elements.titleIcon[0].classList.toggle("shake-constant", states.isRinging);
    }

}

GUI.TableViewV2.Cells.AlarmClockControlCard = AlarmClockControlCard;
