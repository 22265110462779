'use strict';

window.GUI = function (GUI) {
    class AudioZoneV2ControlDetailedViewController extends GUI.ViewController {
        constructor(details) {
            super($('<div/>'));
            this.showStateArgs = details.showStateArgs;
            this.control = details.control;
        }

        viewWillAppear() {
            var prms = [super.viewWillAppear(...arguments) || true];

            if (!this.didAlreadyShowState && this.showStateArgs) {
                // add a dummy screen so that everything works correctly (HD_OVERLAY animation..)
                // Account for the dummy screen when navigatingBackToRoot...
                prms.push(this._handleAddInitialDummy());
                prms.push(Q(this.showStateArgs).then(prmArgs => {
                    return super.showState(...prmArgs);
                }));
                this.didAlreadyShowState = true;
            }

            return Q.all(prms);
        }

        navigateBackToRoot() {
            return super.navigateBackToRoot(1); // Account for the dummy view
        }

        showState(identifier) {
            if (GUI.AudioZoneV2ControlContentViewController.shouldHandleInControlContentVc(identifier)) {
                return this.handleMasterShowState.apply(this, arguments);
            } else {
                if (this.currentView && this.currentView instanceof GUI.AudioZoneV2ControlDetailedContentViewController) {
                    return this._navigateBack(true).then(() => {
                        return super.showState(...arguments);
                    });
                } else {
                    return super.showState(...arguments);
                }
            }
        }

        handleMasterShowState() {
            return this.ViewController.showState.apply(this.ViewController, arguments);
        }

        navigateBack() {
            // check if we are HD and we did show a dummy view!
            if (this.history.length === 2 && !(this.ViewController instanceof GUI.ModalViewController)) {
                return this.dismiss();
            } else {
                return super.navigateBack(...arguments);
            }
        }

        dismiss() {
            if (HD_APP) {
                return this.ViewController.dismiss();
            } else {
                return super.dismiss(...arguments);
            }
        }

    }

    GUI.AudioZoneV2ControlDetailedViewController = AudioZoneV2ControlDetailedViewController;
    return GUI;
}(window.GUI || {});
