'use strict';

GUI.TableViewV2.CellType.Special.MediaBrowserPlayableCell = "MediaBrowserPlayableCell";

class MediaBrowserPlayableCell extends GUI.TableViewV2.Cells.ButtonCell {
    constructor() {
        super(...arguments);
    }

    viewDidLoad() {
        var prms = super.viewDidLoad(...arguments);
        var iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(this.content.item).getIconObjForItem(this.content.item, this.content.details.identifier),
            coverViewPlaceholder = $("<div class='cover-view__placeholder'/>");
        this.contentPlaceholder.prepend(coverViewPlaceholder);
        this.coverView = new Controls.AudioZoneV2Control.CoverViewV2(coverViewPlaceholder, iconObj, true);
        this.addToHandledSubviews(this.coverView);
        return prms;
    }

}

GUI.TableViewV2.Cells.MediaBrowserPlayableCell = MediaBrowserPlayableCell;
