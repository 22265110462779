'use strict';

window.GUI = function (GUI) {
    class VolumeOverlayCell extends GUI.TableViewV2.Cells.BaseCell {
        //region Static
        static Template = class {
            //region Static
            static getContentHeader() {
                return $("<div class='content__header'>" + "   " + "<div class='header__play-state-placeholder'>" + "       " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "play-state play-state--1") + "       " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "play-state play-state--0") + "   " + "</div>" + "   " + "<div class='header__text-container'>" + "          " + "<div class='text-container__title'></div>" + "          " + "<div class='text-container__subtitle'></div>" + "       </div>" + "   </div>" + "</div>");
            }

            static getDisabledText() {
                return $("<div class='content__disabled-text'></div>");
            } //endregion Static


        }; //endregion Static

        constructor() {
            super(...arguments);
            applyMixins(this, StateHandler.Mixin);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                this.control = this.content.control;
                this.contentPlaceholder.append(VolumeOverlayCell.Template.getContentHeader());
                this.playStateContainer = this.element.find(".header__play-state-placeholder");
                this.headerElm = this.element.find(".content__header");
                this.headerTitle = this.headerElm.find(".text-container__title");
                this.headerSubtitle = this.headerElm.find(".text-container__subtitle");
                this.playPauseButton = new GUI.LxButton(this, this.playStateContainer, Color.BUTTON_GLOW, null, true, 250);
                this.playPauseButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.playPauseButton);
                this.playPauseButton.onButtonTapped = this._handleOnPlayPause.bind(this);
                this.appendVolumeControl();
                this.headerTitle.text(this.content.title);
                this.headerSubtitle.text(this.content.subtitle);
                this.contentPlaceholder.append(VolumeOverlayCell.Template.getDisabledText());
                this.disabledText = this.contentPlaceholder.find(".content__disabled-text");
            });
        }

        appendVolumeControl() {
            this.controlStrip = new GUI.AudioZoneV2ControlStrip(this.control, {
                justVolume: true,
                isInAmbientMode: this.isInAmbientMode()
            });
            this.appendSubview(this.controlStrip, this.contentPlaceholder);
        }

        getStateIDs() {
            return ["isPlaying", "isLocked", "power"];
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                this.registerStates();
            }.bind(this));
        }

        viewWillDisappear() {
            this.unregisterStates();
            return super.viewWillDisappear(...arguments);
        }

        registerStates() {
            this._registerForStates(this.control.uuidAction, this.getStateIDs());
        }

        unregisterStates() {
            this._unregisterStates(this.control.uuidAction);
        }

        receivedStates(states) {
            this._setIsPlaying(states.isPlaying); // both setIsLocked & setPowerState affect the same label, so if one is true, don't perform the other.


            if (!this._setIsLocked(states.isLocked)) {
                this._setPowerState(states.power);
            }
        }

        _setIsPlaying(isPlaying) {
            this.isPlaying = isPlaying;
            this.playStateContainer.toggleClass("header__play-state-placeholder--play-state-1", isPlaying);
        }

        _setIsLocked(isLocked) {
            this.setEnabled(!isLocked);

            if (!!isLocked) {
                this.disabledText.text(_("operation-blocked"));
            } else {
                this.disabledText.text("");
            }

            this.contentPlaceholder.toggleClass("cell__content--locked", !!isLocked);
            return isLocked;
        }

        _setPowerState(powerState) {
            var isOffline = powerState === "offline";
            this.setEnabled(!isOffline);

            if (isOffline) {
                this.disabledText.text(_("device-offline"));
            } else {
                this.disabledText.text("");
            }

            this.contentPlaceholder.toggleClass("cell__content--power-offline", isOffline);
        }

        _handleOnPlayPause() {
            if (this.isPlaying) {
                this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                    cmd: MusicServerEnum.AudioCommands.PAUSE
                });
            } else if (this.control.getStates().canPlayMusic) {
                this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                    cmd: MusicServerEnum.AudioCommands.PLAY
                });
            } // won't send play, as it cannot play music right now.

        }

    }

    GUI.TableViewV2.Cells.VolumeOverlayCell = VolumeOverlayCell;
    GUI.TableViewV2.CellType.Special.VOLUME_OVERLAY_CELL = "VolumeOverlayCell";
    return GUI;
}(window.GUI || {});
