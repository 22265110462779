'use strict';

class SteakControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    //region Static
    static Template = function () {
        var getSensorsContainer = function getSensorsContainer() {
            return $('<div class="subtitle__container">' + '   <div class="container__device-state"></div>' + '   ' + getSensor(true) + '   ' + getSensor(false) + '   <div class="device-state__subtitle"></div>' + '</div>');
        };

        var getSensor = function getSensor(left) {
            var sensorPostfix = left ? "left" : "right";
            return "<div class='container__sensor sensor--" + sensorPostfix + "'>" + "    " + "<div class='sensor__color'></div>" + "    " + "<div class='sensor__temp'></div>" + "</div>";
        };

        return {
            getSensorsContainer: getSensorsContainer
        };
    }(); //endregion Static

    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    viewDidLoad() {
        var promises = [super.viewDidLoad(...arguments)];
        this.elements.sensorsContainter = SteakControlCard.Template.getSensorsContainer();
        this.elements.sensors = {
            left: {
                element: this.elements.sensorsContainter.find(".sensor--left")
            },
            right: {
                element: this.elements.sensorsContainter.find(".sensor--right")
            }
        };
        this.elements.sensors.left.color = this.elements.sensors.left.element.find('.sensor__color');
        this.elements.sensors.left.temp = this.elements.sensors.left.element.find('.sensor__temp');
        this.elements.sensors.right.color = this.elements.sensors.right.element.find('.sensor__color');
        this.elements.sensors.right.temp = this.elements.sensors.right.element.find('.sensor__temp');
        promises.push(GUI.animationHandler.schedule(function () {
            this.elements.subtitle.append(this.elements.sensorsContainter);
            this.elements.sensors.left.element.hide();
            this.elements.sensors.right.element.hide();
        }.bind(this)));
        return Q.all(promises);
    }

    getStateIDs() {
        return super.getStateIDs(...arguments).concat("currentTemperatures", "sensors", "timerRemaining", "timerInfo", "isActive");
    }

    receivedStates(states) {
        super.receivedStates(...arguments);

        if (states.isActive && states.deviceState === 0 && !states.universalIsLocked) {
            if (this.control.hasConnectedSensors(states)) {
                states.sensors.forEach(function (sensor, idx) {
                    if (sensor.connected) {
                        this.setSensorSubtitle(sensor.isLeft, states.currentTemperatures[idx]);
                    } else {
                        // Hide the not connected sensor
                        this.setSensorSubtitle(sensor.isLeft, false);
                    }
                }.bind(this));
            } else if (states.timerInfo.active) {
                this.setSubtitle(LxDate.formatSecondsIntoDigits(states.timerRemaining, true, true));
            } else {
                this.setSubtitle(LxDate.formatSecondsIntoDigits(states.timerInfo.duration, true, true));
            }
        }
    }

    // Overwrite the base classes setSubtitle function to have control over the DOM element it self
    setSubtitle(subtitle) {
        var deviceStateSubtitle = this.elements.subtitle.find('.device-state__subtitle');

        if (typeof subtitle === "string" && subtitle !== "" && subtitle !== NBR_SPACE) {
            GUI.animationHandler.schedule(function () {
                deviceStateSubtitle.text(subtitle).css("display", "");
            }); // Hide both sensor subtitles if we show a normal subtitle

            this.setSensorSubtitle(true, false);
            this.setSensorSubtitle(false, false);
        } else {
            GUI.animationHandler.schedule(function () {
                deviceStateSubtitle.hide().text("");
            });
        }
    }

    /**
     * Sets the correct subtitles (color and temp with °), also hides the default subtitle
     * @param isLeft
     * @param temp If temp is false the sensor subtitle will be hidden
     */
    setSensorSubtitle(isLeft, temp) {
        // the element may not be initialized on the first call from the base class
        if (!this.elements.sensors) {
            return;
        }

        var sensorKey = isLeft ? "left" : "right",
            sensorObj = this.elements.sensors[sensorKey],
            // Get the correct sensor container object
            currentTempString,
            sensorColor; // Hide the specific subtitle for the sensor if the temp is false

        if (temp === false) {
            GUI.animationHandler.hide(sensorObj.element);
        } else {
            currentTempString = lxFormat("%d°", temp);
            sensorColor = this.control.getColorForSensorAtIndex(+!isLeft);
            GUI.animationHandler.schedule(function () {
                sensorObj.element.show();
                sensorObj.color.css("background-color", sensorColor);
                sensorObj.temp.text(currentTempString);
            }); // Hide the other subtitle (used to display the short text)

            this.setSubtitle(false);
        }
    }

}

GUI.TableViewV2.Cells.SteakControlCard = SteakControlCard;
