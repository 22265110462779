'use strict';

/*window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.JALOUSIE = "JalousieControlStateCell";

    class JalousieControlStateCell extends GUI.TableViewV2.Cells.ControlStateCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        getStateIDs() {
            var stateIds = super.getStateIDs(...arguments);
            stateIds.push("locked", "safetyActive", "autoAllowed", "autoActive");
            return stateIds;
        }

        receivedStates(states) {
            super.receivedStates(...arguments); // locked blinds, or ones where the safety is active need to be represented properly

            if (states.locked || states.safetyActive) {
                super.setDisclosureText("!");
                this.setDisclosureTextColor(Color.RED);
            } else {
                // The automatic "a" is always shown, as it indicates that it supports the automatic feature.
                super.setDisclosureText(this.control.details.isAutomatic ? "A" : null); // and the color indicates whether it's active or not.

                this.setDisclosureTextColor(states.autoActive ? window.Styles.colors.stateActive : states.autoAllowed ? null : window.Styles.colors.orange);
            }
        }

        // just override, so that the base class's call doesn't do anything
        setDisclosureText() {
        }

    }

    GUI.TableViewV2.Cells.JalousieControlStateCell = JalousieControlStateCell;
    return GUI;
}(window.GUI || {}); */
