'use strict';

class IRoomControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    getTitle2() {
        if (this.content.displayControlName) {
            return this.control.getName(); // will show the name
        } else {
            return this._getStateDependentTitle2();
        }
    }

    _getStateDependentTitle2() {
        var states = this.control.getStates();

        if (states.serviceMode) {
            return _("controls.irc.mode.service");
        } else if (states.controllerMode !== undefined) {
            return this.control.getNameOfIRCMode(states.controllerMode);
        } else {
            return super.getTitle2(...arguments); // will either show the name or the state text
        }
    }

    getSubtitle() {
        var subtitle = super.getSubtitle(...arguments);

        if (this.content.displayControlName) {
            return this._getStateDependentTitle2();
        } else {
            return subtitle;
        }
    }

}

GUI.TableViewV2.Cells.IRoomControlCard = IRoomControlCard;
