'use strict';

window.Controls = function (Controls) {
    function ControlDisabler() {
        let weakThis = this;
        weakThis.disabler = {
            disabled: false
        };
        weakThis.disabler.commandsInQueue = false;
        weakThis.disabler.allStatesReceived = false;

        weakThis.__proto__.disableControl = function disableControl() {
            Debug.Control.Disabler && console.log("ControlDisabler disableControl:" + this.control.uuidAction);

            if (!this.disabler.disabled) {
                this.disabler.disabled = true;
                var statusElements = this.getStatusElements && this.getStatusElements();

                if (!!statusElements && typeof statusElements === "object") {
                    // may be null!
                    if (statusElements.full) {
                        statusElements.full.forEach(function (statusElement) {
                            statusElement.addClass("control-area--outdated");
                        });
                    }

                    if (statusElements.single) {
                        statusElements.single.forEach(function (statusElement) {
                            statusElement.addClass("control-area__outdated");
                        });
                    }

                    if (statusElements.singleRight) {
                        statusElements.singleRight.forEach(function (statusElement) {
                            statusElement.addClass("control-area__outdated-right");
                        });
                    }
                } else {
                    this.element.addClass("disabled");
                }
            } else {
                Debug.Control.Disabler && console.log(" - already disabled");
            }
        };

        weakThis.__proto__.enableControl = function enableControl() {
            Debug.Control.Disabler && console.log("ControlDisabler enableControl:" + this.control.uuidAction);

            if (this.disabler.disabled) {
                this.disabler.disabled = false;
                this.element.find(".control-area--outdated").removeClass("control-area--outdated");
                this.element.find(".control-area__outdated").removeClass("control-area__outdated");
                this.element.find(".control-area__outdated-right").removeClass("control-area__outdated-right");
                this.element.removeClass("disabled");
            } else {
                Debug.Control.Disabler && console.log(" - already enabled");
            }
        };

        weakThis.__proto__.setCommandsInQueue = function setCommandsInQueue(inQueue) {
            Debug.Control.Disabler && console.log("ControlDisabler setCommandsInQueue:" + this.control.uuidAction + ", " + inQueue);

            if (this.disabler.commandsInQueue === inQueue) {
                Debug.Control.Disabler && console.log(" - inQueue didn't change");
                return;
            }

            this.disabler.commandsInQueue = inQueue;

            if (inQueue) {
                this.disableControl.call(this);
                this.disabler.spinner = $('<div class="control-cell__command-in-queue">' + '   <div class="command-in-queue__spinner css-spinner-a">' + '   </div>' + '</div>');
                this.element.append(this.disabler.spinner);
            } else {
                if (this.disabler.spinner) {
                    this.disabler.spinner.hide();
                    delete this.disabler.spinner;
                }

                if (this.disabler.allStatesReceived) {
                    this.enableControl.call(this);
                }
            }
        };

        weakThis.__proto__.setHasAllStatesReceived = function setHasAllStatesReceived(allStatesReceived) {
            Debug.Control.Disabler && console.log("setHasAllStatesReceived: " + allStatesReceived + " for Control with UUID: " + this.control.uuidAction);
            this.disabler.allStatesReceived = allStatesReceived;

            if (this.disabler.allStatesReceived && !this.disabler.commandsInQueue) {
                this.enableControl.call(this);
            } else {
                this.disableControl.call(this);
            }
        };

        weakThis.disableControl();
    }

    Controls.ControlDisabler = ControlDisabler;
    return Controls;
}(window.Controls || {});
