'use strict';

GUI.TableViewV2.CellType.Special.AudioVolCell = "AudioVolCell";

class AudioVolCell extends GUI.TableViewV2.Cells.SliderCell {
    //region Static
    static Template = class {
        //region Static
        static getPlayStateButton() {
            return $("" + "<div class='label-placeholder__play-state label-placeholder__play-state--pause'>" + "   " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "play-state__icon--play") + "   " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "play-state__icon--pause") + "</div>");
        } //endregion Static


    }; //endregion Static

    constructor() {
        super(...arguments);
        Object.assign(this, StateHandler.Mixin);
    }

    viewDidLoad() {
        return Q(super.viewDidLoad(...arguments) || true).then(function () {
            this.elements.topContainer = this.contentPlaceholder.find(".label-placeholder");
            this.playStateButtonElm = AudioVolCell.Template.getPlayStateButton();
            this.playStateButton = new GUI.LxButton(this, this.playStateButtonElm);
            this.appendSubview(this.playStateButton, this.elements.topContainer);
            this.playStateButton.onButtonTapped = this._setPlayStateIcon.bind(this);
            this.control = this.content.zone;

            this._registerForStates(this.control.uuidAction, ["volume", "isPlaying"]);
        }.bind(this));
    }

    destroy() {
        this._unregisterStates(this.control.uuidAction);

        return super.destroy(...arguments);
    }

    receivedStates(states) {
        this.states = states;
        this.setPosition(states.volume);
        this.playStateButtonElm.toggleClass("label-placeholder__play-state--pause", !states.isPlaying);
    }

    _setPlayStateIcon() {
        this.control.sendCommand(this.states.isPlaying ? Commands.AudioZone.CLIENT.PAUSE : Commands.AudioZone.CLIENT.PLAY);
    }

}

GUI.TableViewV2.Cells.AudioVolCell = AudioVolCell;
