'use strict';

window.GUI = function (GUI) {
    class NoPlaylistView extends GUI.BackgroundView {
        constructor(control, createPlFn) {
            super(Icon.AudioZone.NEW.PLAYLIST, _('media.playlist.no-playlist-existing'), null, _('media.playlist.create-short'), function () {
                createPlFn();
            }, true);
            this.control = control;
        }

    }

    GUI.NoPlaylistView = NoPlaylistView;
    return GUI;
}(window.GUI || {});
