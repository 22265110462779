'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneControl = Controls.AudioZoneControl || {};

    /**
     * coverurl         // the full url specifying where to download an image
     * iconSrc          // if no cover is provided, an icon is shown. An svg that is provided by the app.
     * [type]           // the FileType, used to determine an icon if coverurl & iconSrc aren't provided
     * [audiotype]      // the AudioType, if nothing is given so far, this is used to get a default icon. (stream, track, lineIn)
     * [iconColor]      // If an icon is shown, it'll be shown in this color. Default: window.Styles.colors.green
     * */

    class CoverView extends GUI.View {
        //region Static
        static Template = function () {
            var getControlTemplate = function getContainer(config) {
                var result;
                var cover = config.hasOwnProperty(MediaEnum.Event.COVER) ? config[MediaEnum.Event.COVER] : "";
                result = '<img class="lx-cover-view__image lx-cover-view__cover" src="' + cover + '"/>';
                var icon = config.hasOwnProperty('iconSrc') ? config.iconSrc : "";
                result += ImageBox.getResourceImageWithClasses(icon, "lx-cover-view__image lx-cover-view__no-cover");
                return result;
            };

            return {
                getControlTemplate: getControlTemplate
            };
        }(); //endregion Static

        /**
         * Initializes a coverView that can show both external covers or icons provided by the app itself.
         * @param [config]  the initial config. optional
         */
        constructor(config) {
            Debug.Control.AudioZone.CoverView && console.log("CoverView ctor");
            super($('<div class="lx-cover-view"></div>'));
            this.lastCoverUrl = "no-cover-yet"; // random text to make sure the cover is updated

            this.config = null;

            if (config != null) {
                this._updateConfig(config);
            }
        }

        viewDidLoad() {
            Debug.Control.AudioZone.CoverView && console.log("+", this.name, "viewDidLoad");
            super.viewDidLoad(...arguments);
            this.element.append(CoverView.Template.getControlTemplate(this.config ? this.config : {}));
            this.elements = {};
            this.elements.cover = $(this.element.find('.lx-cover-view__cover'));
            this.elements.noCover = $(this.element.find('.lx-cover-view__no-cover'));
        }

        viewWillAppear() {
            Debug.Control.AudioZone.CoverView && console.log("+", this.name, "viewWillAppear");
            super.viewWillAppear(...arguments); // check if an initial config was provided. if so, use it.

            if (this.config) {
                this._updateUI();
            }
        }

        activateImageFill() {
            this.element.addClass("lx-cover-view--fill");
        }

        setConfig(config) {
            Debug.Control.AudioZone.CoverView && console.log("CoverView setConfig");

            if (this._updateConfig(config)) {
                this._updateUI();
            }
        }

        _updateConfig(config) {
            Debug.Control.AudioZone.CoverView && console.log("CoverView _updateConfig: " + JSON.stringify(config));
            var oldConfig = JSON.stringify(this.config);
            this.config = {};
            this.config = arrayMap([config], [MediaEnum.Event.COVER, 'iconSrc', 'iconColor', MediaEnum.Event.FILE_TYPE, MediaEnum.Event.AUDIO_TYPE])[0];
            this.config.hasCover = this.config.hasOwnProperty(MediaEnum.Event.COVER) && this.config[MediaEnum.Event.COVER] !== ""; // no covers when in restricted mode, only icons!

            if (MediaServerComp.isRestricted()) {
                this.config.hasCover = false;
                this.config.cover = "";
            } // make sure an iconColor is specified


            if (!this.config.hasOwnProperty("iconColor")) {
                this.config.iconColor = Color.MEDIA_DEFAULT_ICON_COLOR;
            } // make sure there is an icon.


            if (!this.config.hasOwnProperty("iconSrc") || this.config.iconSrc === "") {
                this.config.iconSrc = ""; // try to aquire an iconSrc

                if (this.config.hasOwnProperty("type")) {
                    this.config.iconSrc = MediaServerComp.defaultIconForType(this.config.type);
                }

                if ((this.config.iconSrc === "" || this.config.iconSrc === MediaServerComp.getDefaultIconForUnknown()) && this.config.hasOwnProperty(MediaEnum.Event.AUDIO_TYPE)) {
                    this.config.iconSrc = MediaServerComp.defaultIconForAudioType(this.config[MediaEnum.Event.AUDIO_TYPE]);

                    if (this.config.iconSrc === MediaServerComp.getDefaultIconForUnknown() && this.config[MediaEnum.Event.FILE_TYPE]) {
                        config.iconSrc = MediaServerComp.defaultIconForType(this.config[MediaEnum.Event.FILE_TYPE]);
                    }
                }

                if (this.config.iconSrc === "") {
                    // Probably initial
                    this.config.iconSrc = Icon.AudioZone.SLEEP;
                }
            }

            return oldConfig !== JSON.stringify(this.config);
        }

        _updateUI() {
            Debug.Control.AudioZone.CoverView && console.log("CoverView _updateUI");

            if (!this.elements) {
                Debug.Control.AudioZone.CoverView && console.warn("No elements yet - cannot update ui of coverview");
                return;
            } //TODO-woessto: why does the coverView receive updates while the element is no longer around?


            if (!this.element) {
                Debug.Control.AudioZone.CoverView && console.warn("This.element doesn't exists! Cannot update ui of coverview");
                return;
            }

            try {
                var newDefaultIcon = ImageBox.getResourceImageWithClasses(this.config.iconSrc, "lx-cover-view__image lx-cover-view__no-cover");

                if (this.elements.noCover) {
                    this.element[0].removeChild(this.elements.noCover[0]);
                }

                this.element.prepend(newDefaultIcon);
                this.elements.noCover = $(this.element.find('.lx-cover-view__no-cover'));

                if (this.config.hasCover) {
                    if (!this.elements.cover) {
                        Debug.Control.AudioZone.CoverView && console.warn("There is no cover element! Cannot update the coverview.");
                        return;
                    } // check if the cover really has changed. Faulty cover-urls otherwise cause a continuous reload.


                    if (this.lastCoverUrl !== this.config[MediaEnum.Event.COVER]) {
                        this.elements.cover[0].setAttribute("src", this.config[MediaEnum.Event.COVER]);
                    }

                    this.lastCoverUrl = this.config[MediaEnum.Event.COVER];
                    this.elements.noCover.hide();
                    this.elements.cover.show();
                } else {
                    if (this.elements.cover) {
                        this.elements.cover[0].setAttribute("src", "");
                    }

                    this.elements.noCover.show();
                    this.elements.cover.hide();
                    this.lastCoverUrl = null;
                }

                this.elements.noCover.css('fill', this.config.iconColor);
            } catch (exc) {
                console.error(exc.stack);
            }
        }

    }

    Controls.AudioZoneControl.CoverView = CoverView;
    return Controls;
}(window.Controls || {});
