'use strict';

class WeatherControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    onSelected() {
        NavigationComp.showState(ScreenState.Weather);
    }

}

GUI.TableViewV2.Cells.WeatherControlCard = WeatherControlCard;
