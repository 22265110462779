'use strict';

window.GUI = function (GUI) {
    class AudioZoneV2ControlStrip extends GUI.View {
        //region Static
        static Template = class {
            //region Static
            static getControlStrip() {
                return $("" + "<div class='audio-zone-v2-control-strip'>" + "   " + "<div class='control-strip__button-placeholder button-placeholder--group'>" + "       " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED, "button-placeholder__icon") + "   </div>" + "   " + "<div class='control-strip__button-group'>" + "       " + "<div class='button-group__button-placeholder button-placeholder--vol-down'>" + "           " + ImageBox.getResourceImageWithClasses(Icon.AudioZoneCentral.VOLUME_DOWN, "button-placeholder__icon") + "       </div>" + "       " + "<div class='button-group__volume-container'>" + "           <div class='volume-container__text'></div>" + "       </div>" + "       " + "<div class='button-group__button-placeholder button-placeholder--vol-up'>" + "           " + ImageBox.getResourceImageWithClasses(Icon.AudioZoneCentral.VOLUME_UP, "button-placeholder__icon") + "       </div>" + "   </div>" + "   " + "<div class='control-strip__button-placeholder button-placeholder--queue'>" + "       " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.QUEUE, "button-placeholder__icon") + "   </div>" + "</div>");
            }

            static getControlStripHD() {
                return $("" + "<div class='audio-zone-v2-control-strip'>" + "   " + "<div class='control-strip__button-group'>" + "       " + "<div class='button-group__button-placeholder button-placeholder--group'>" + "           " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.GROUP, "button-placeholder__icon") + "       </div>" + "       " + "<div class='button-group__button-placeholder button-placeholder--vol-down'>" + "           " + ImageBox.getResourceImageWithClasses(Icon.AudioZoneCentral.VOLUME_DOWN, "button-placeholder__icon") + "       </div>" + "       " + "<div class='button-group__volume-container'>" + "           <div class='volume-container__text'></div>" + "       </div>" + "       " + "<div class='button-group__button-placeholder button-placeholder--vol-up'>" + "           " + ImageBox.getResourceImageWithClasses(Icon.AudioZoneCentral.VOLUME_UP, "button-placeholder__icon") + "       </div>" + "   </div>" + "</div>");
            } //endregion Static


        }; //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        BUTTON_KEY = {
            GROUP: "group",
            VOL_DOWN: "volDown",
            VOL: "vol",
            VOL_UP: "volUp",
            QUEUE: "queue"
        }; //endregion Private

        /**
         *
         * @param control
         * @param cfg       may contain hudTarget, forEco, isMasterVol, justVol, hudOpen
         */
        constructor(control, cfg) {
            super(HD_APP ? AudioZoneV2ControlStrip.Template.getControlStripHD() : AudioZoneV2ControlStrip.Template.getControlStrip());
            applyMixins(this, StateHandler.Mixin);
            this.cfg = cfg || {};

            if (this.cfg.isMasterVol) {
                this._viewId = this.viewId + "-Master@" + control.audioserverComp.getServerName();
            }

            this.control = control;
            this.volumeHud = this.cfg.volumeHud;
            this.dismissFn = this.cfg.dismissFn;this.buttons = {};
            this.antiGhostTimer = new AntiGhostTimer(this._antiGhostTimerFired.bind(this), 4000);

            this.element.toggleClass("control-strip--in-ambient-mode", !!this.cfg.isInAmbientMode)
        }

        getStateIDs() {
            if (this.cfg.isMasterVol) {
                return [MusicServerEnum.Event.MASTER_VOLUME];
            } else {
                return ["volume"];
            }
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements = {};
                this.elements.groupBtnElm = this.element.find(".button-placeholder--group");
                this.elements.volDownBtnElm = this.element.find(".button-placeholder--vol-down");
                this.elements.vol = this.element.find(".button-group__volume-container");
                this.elements.volumeDisplay = this.element.find(".volume-container__text");
                this.elements.volUpBtnElm = this.element.find(".button-placeholder--vol-up");

                if (!HD_APP) {
                    this.elements.queueBtnElm = this.element.find(".button-placeholder--queue");
                }

                this.buttons[this.BUTTON_KEY.GROUP] = new GUI.LxButton(this, this.elements.groupBtnElm, Color.BUTTON_GLOW, null, true, 250);
                this.buttons[this.BUTTON_KEY.VOL_DOWN] = new GUI.LxButton(this, this.elements.volDownBtnElm, Color.BUTTON_GLOW, null, true, 250);
                this.buttons[this.BUTTON_KEY.VOL] = new GUI.LxButton(this, this.elements.vol, Color.BUTTON_GLOW);
                this.buttons[this.BUTTON_KEY.VOL_UP] = new GUI.LxButton(this, this.elements.volUpBtnElm, Color.BUTTON_GLOW, null, true, 250);
                this.elements.queueBtnElm && (this.buttons[this.BUTTON_KEY.QUEUE] = new GUI.LxButton(this, this.elements.queueBtnElm, Color.BUTTON_GLOW, null, true, 250));
                Object.keys(this.buttons).forEach(function (buttonKey) {
                    this.addToHandledSubviews(this.buttons[buttonKey]);
                    this.buttons[buttonKey].useChildsAsActiveParts("fill");
                    this.buttons[buttonKey].onButtonTapped = this._handleButtonTapped.bind(this, buttonKey);
                    this.buttons[buttonKey].onButtonTicked = this._handleButtonTicked.bind(this, buttonKey);
                }.bind(this));

                if (!this._doesShowVolumeValue()) {
                    this.hideSubview(this.buttons[this.BUTTON_KEY.VOL]);
                }

                if (!this.control.isFixedGroup && !this.control.audioserverComp.supportsDynamicGroups()) {
                    if (HD_APP) {
                        this.elements.groupBtnElm.hide();
                    } else {
                        this.elements.groupBtnElm.css("visibility", "hidden");
                    }
                }

                if (this.cfg.justVolume) {
                    this.elements.groupBtnElm.hide();
                    this.elements.queueBtnElm && this.elements.queueBtnElm.hide();
                }
            }.bind(this));
        }

        viewDidAppear() {
            return super.viewDidAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDs());
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates();

            this.antiGhostTimer && this.antiGhostTimer.resetAntiGhostTimer();
            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            this.states = states;
            var newVolume;

            if (this.cfg.isMasterVol) {
                newVolume = states[MusicServerEnum.Event.MASTER_VOLUME];
            } else {
                newVolume = states.volume;
            }

            if (this._lastUpdateVolume !== newVolume) {
                if (this.cfg.isMasterVol) {
                    Debug.Control.AudioZone.MasterVolume && console.log(this.viewId, "receivedStates: masterVolume=" + newVolume);
                }

                this._lastUpdateVolume = newVolume;
                Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "receivedStates: ", newVolume);

                this._updateVolumeValue(newVolume, true);
            }

            GUI.animationHandler.schedule(function () {
                if (this.control.audioserverComp.supportsDynamicGroups()) {
                    this.buttons[this.BUTTON_KEY.GROUP].setInactiveColor(states.isSynced ? states.syncColor : Color.WHITE);
                } else {
                    this.buttons[this.BUTTON_KEY.GROUP].setInactiveColor(states.syncColor || Color.WHITE);
                }
            }.bind(this));
        }

        /**
         * Deals with updating the internally stored value and updating the GUI. Also minds the antiGhostTimer.
         * @param newValue
         * @param fromStates
         * @param fromTimer
         * @returns {*}
         * @private
         */
        _updateVolumeValue(newValue, fromStates, fromTimer) {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_updateVolumeValue: ", newValue, fromStates, fromTimer); // values modified by the user (e.g. not from states) are always used. State values only if there's no timer.

            if (!this._doesShowVolumeValue()) {
                return; // e.g. with external up/down
            } // values modified by the user (e.g. not from states) are always used. State values only if there's no timer.


            if (!fromStates || !this._antiGhostActive()) {
                Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "      update gui & internal value");
                this._currVolume = newValue;
                GUI.animationHandler.setText(this.elements.volumeDisplay, newValue);
            } // Event values are always cached.


            if (fromStates) {
                Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "      update cache value");
                this._cachedVolume = newValue;
            }

            return this._currVolume;
        }

        _doesShowVolumeValue() {
            return !this.control.externalUpDownVolume || this.cfg.isMasterVol;
        }

        _antiGhostTimerFired() {
            this._updateVolumeValue(this._cachedVolume, true, true);
        }

        _antiGhostActive() {
            return this.antiGhostTimer && this.antiGhostTimer.isAntiGhostTimerActive();
        }

        /**
         * Called e.g. when the screensaver becomes active.
         */
        resignActive() {
            this._hideHud();
        }

        _handleButtonTapped(buttonKey) {
            switch (buttonKey) {
                case this.BUTTON_KEY.GROUP:
                case this.BUTTON_KEY.VOL:
                    if (!this.cfg.hudOpen) {
                        this._respondToHudButton();
                    } else {
                        this._hideHud();
                    }

                    break;

                case this.BUTTON_KEY.VOL_DOWN:
                    this._handleVolumeDown();

                    break;

                case this.BUTTON_KEY.VOL_UP:
                    this._handleVolumeUp();

                    break;

                case this.BUTTON_KEY.QUEUE:
                    this._hideHud();

                    var viewCtrl = this.control.audioserverComp.AudioViewController;
                    viewCtrl.showState(Controls.AudioZoneV2Control.Enums.ScreenState.PLAYER, null, {
                        control: this.control,
                        showQueue: true
                    }, null, true);
                    break;
            }
        }

        _respondToHudButton() {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_respondToHudButton");

            if (!this.control.audioserverComp.supportsDynamicGroups() && !this.control.getStates().isSynced) {
                return;
            }

            if (!this.cfg.justVolume) {
                if (this.hud && !this.hud.isVisible()) {
                    this._hideHud();
                }

                this.hud ? this._hideHud() : this._showHud();
            }
        }

        _hideHud(isCreator) {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_hideHud");
            var trgt = this.cfg.hudTarget ? this.cfg.hudTarget : this.ViewController;

            this.getElement().toggleClass("control-strip--in-ambient-mode", this.cfg.isInAmbientMode);
            if (this.hud) {
                if (this.hud.dismissFn && !isCreator) {
                    // make use of the dismissFn, the HUD may have been shown by a different controlStrip - this way
                    // the "initiating" controlStrip can reset it's volumeHud property too.
                    this.hud.dismissFn();
                } else {
                    trgt.removeSubview(this.hud); // also destroys it, as keepalive property isn't set.
                }

                this.hud = null;
            } else if (this.dismissFn) {
                this.dismissFn();
            }
        }

        _showHud() {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_showHud");
            var trgt = this.cfg.hudTarget ? this.cfg.hudTarget : this.ViewController;
            this.hud = new GUI.AudioZoneV2ControlStripOverlay(this.control, this._hideHud.bind(this, true), !!this.cfg.forEco);
            trgt.appendSubview(this.hud).then(() => {
                this.getElement().toggleClass("control-strip--in-ambient-mode", false);
            });
        }

        _handleButtonTicked(buttonKey) {
            switch (buttonKey) {
                case this.BUTTON_KEY.VOL_DOWN:
                    this._handleVolumeDown();

                    break;

                case this.BUTTON_KEY.VOL_UP:
                    this._handleVolumeUp();

                    break;
            }
        }

        _handleVolumeUp() {
            var newVolume;

            if (this.states.isPlaying || this.cfg.isMasterVol) {
                this.antiGhostTimer && this.antiGhostTimer.fire();
                newVolume = this._handleVolumeChangeTo(this._currVolume + this.states.volumeStep);
            } else {
                // needs to be turned on first. don't increment
                newVolume = this._currVolume;
            }

            if (this.cfg.isMasterVol) {
                this._sendWithMinCycle(MusicServerEnum.AudioCommands.MASTER_VOLUME.SET_TO + newVolume);
            } else {
                if (this.control.externalUpDownVolume) {
                    Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_handleVolumeUp - send step to miniserver!");

                    this._sendToMiniserver(MusicServerEnum.AudioCommands.VOLUME.UP);
                } else {
                    Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_handleVolumeUp - regular volume");

                    this._send(MusicServerEnum.AudioCommands.VOLUME.UP_BY + this.states.volumeStep);
                }
            }
        }

        _handleVolumeDown() {
            var newVolume;

            if (this.states.isPlaying || this.cfg.isMasterVol) {
                this.antiGhostTimer && this.antiGhostTimer.fire();
                newVolume = this._handleVolumeChangeTo(this._currVolume - this.states.volumeStep);
            } else {
                // needs to be turned on first, don't decrement
                newVolume = this._currVolume;
            }

            if (this.cfg.isMasterVol) {
                this._sendWithMinCycle(MusicServerEnum.AudioCommands.MASTER_VOLUME.SET_TO + newVolume);
            } else {
                if (this.control.externalUpDownVolume) {
                    Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_handleVolumeDown - send step!");

                    this._sendToMiniserver(MusicServerEnum.AudioCommands.VOLUME.DOWN);
                } else {
                    Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_handleVolumeDown - regular volume");

                    this._send(MusicServerEnum.AudioCommands.VOLUME.DOWN_BY + this.states.volumeStep);
                }
            }
        }

        _handleVolumeChangeTo(newVolume) {
            var newValue = Math.max(0, newVolume);
            newValue = Math.min(100, newValue);
            return this._updateVolumeValue(newValue);
        }

        _send(cmd) {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "send: " + cmd);

            if (Debug.Control.AudioZone.MasterVolume && this.cfg.isMasterVol) {
                Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_send:" + cmd);
            }

            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: cmd
            });
        }

        _sendToMiniserver(cmd) {
            this.control.sendCommand(cmd);
        }

        /**
         * Used for sending absolute values like the master volume. Ensures a minimum delay of 1 second in between
         * two commands.
         * @param cmd
         * @private
         */
        _sendWithMinCycle(cmd) {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_sendWithMinCycle: " + cmd);

            if (!this._resendCycle) {
                this._send(cmd);

                this._resendCycle = setTimeout(this._checkCacheAndSend.bind(this), 1000);
            } else {
                this._cachedCmd = cmd;
            }
        }

        /**
         * Will check if there's a cached command to be sent - if so, sends.
         * @private
         */
        _checkCacheAndSend() {
            Debug.Control.AudioZone.ControlStrip && console.log(this.viewId, "_checkCacheAndSend");
            this._resendCycle = null;

            if (this._cachedCmd) {
                this._sendWithMinCycle(this._cachedCmd);

                this._cachedCmd = null;
            }
        }

    }

    GUI.AudioZoneV2ControlStrip = AudioZoneV2ControlStrip;
    return GUI;
}(window.GUI || {});
