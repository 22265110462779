'use strict';

window.GUI = function (GUI) {
    class AudioZoneV2ControlDetailedContentViewController extends GUI.ViewController {
        constructor(details) {
            super($('<div/>'));
            applyMixins(this, ContextMenuHandler.Mixin);
            this.showStateArgs = details.showStateArgs;
            this.control = details.control;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.setUpComfortUI();
            }.bind(this));
        }

        applyDragRegion() {// We don't want that here!
        }

        viewWillAppear() {
            var prms = [super.viewWillAppear(...arguments) || true];

            if (!this.didAlreadyShowState && this.showStateArgs) {
                // add a dummy screen so that everything works correctly (HD_OVERLAY animation..)
                // Account for the dummy screen when navigatingBackToRoot...
                prms.push(this._handleAddInitialDummy());
                prms.push(Q(this.showStateArgs).then(prmArgs => {
                    return super.showState(...prmArgs);
                }));
                this.didAlreadyShowState = true;
            }

            return Q.all(prms);
        }

        updateView(details) {
            var args = arguments;

            if (details.hasOwnProperty("showStateArgs") && details.hasOwnProperty("control")) {
                this.showStateArgs = details.showStateArgs;
                this.control = details.control;
                return this._navigateBackToDummyView().then(() => {
                    return Q(this.showStateArgs).then(prmArgs => {
                        // Always use the Fade animation
                        prmArgs[3] = AnimationType.FADE;
                        return super.showState(...prmArgs).then(() => {
                            return super.updateView(...args);
                        });
                    });
                });
            }

            return super.updateView(...args);
        }

        _navigateBackToDummyView() {
            var i = this.history.stack.length - 1,
                all = [true];

            while (i > 0) {
                // IMPORTANT: Don't call this.navigateBack, it'll dismiss the ViewController once finished!
                all.push(super._navigateBack());
                i--;
            }

            return Q.all(all);
        }

        destroyOnBackNavigation() {
            return true;
        }

        showState(identifier) {
            if (GUI.AudioZoneV2ControlContentViewController.shouldHandleInControlContentVc(identifier)) {
                return this.handleMasterShowState.apply(this, arguments);
            } else {
                return super.showState(...arguments);
            }
        }

        handleMasterShowState() {
            return this.ViewController.ViewController.showState.apply(this.ViewController.ViewController, arguments);
        }

        navigateBackToRoot() {
            return super.navigateBackToRoot(1); // Account for the dummy view
        }

        navigateBack() {
            if (this.history.stack.length > 2) {
                return super.navigateBack(...arguments).then(function () {
                    this._checkTitlebar();
                }.bind(this));
            } else {
                return this.dismiss();
            }
        }

        titleBarText() {
            if (this.currentView) {
                return this.currentView.viewControllerTitleText && this.currentView.viewControllerTitleText();
            } else {
                return super.titleBarText(...arguments);
            }
        }

        titleBarSubtitle() {
            if (this.currentView) {
                return this.currentView.viewControllerSubtitleText && this.currentView.viewControllerSubtitleText();
            } else {
                return null;
            }
        }

        // Increase the tap area by also forward a tap on the title to the subtitle
        onTitleTapped() {
            this.onSubtitleTapped.apply(this, arguments);
        }

        onSubtitleTapped() {
            if (this.handleOnSubtitleTapped) {
                this.handleOnSubtitleTapped.apply(this, arguments);
            }
        }

        getLeftIcon() {
            if (this._isPresentedModal()) {
                return (HD_APP) ? Icon.Buttons.CLOSE : null;
            } else if (HD_APP) {
                if (this.history.stack.length > 2) {
                    return Icon.Buttons.BACK;
                }
            } else {
                return Icon.Buttons.HOME;
            }
        }

        getRightIcon() {
            if (this._isPresentedModal() && (!HD_APP)) {
                return null;
            } else if (!HD_APP) {
                return Icon.Buttons.SEARCH;
            } else if (this.currentView && this.currentView.getRightVCButtonIcon) {
                return this.currentView.getRightVCButtonIcon();
            }
        }

        leftAction() {
            if (HD_APP) {
                return this.navigateBack();
            } else {
                return this.dismiss();
            }
        }

        rightAction() {
            if (HD_APP) {
                if (this.currentView.getRightVCButtonIconFn) {
                    var fn = this.currentView.getRightVCButtonIconFn();
                    fn && fn();
                }
            } else {
                var details = {
                    control: this.control,
                    delegate: this.currentView
                };
                this.ViewController.showState(Controls.AudioZoneV2Control.Enums.ScreenState.MEDIA_SEARCH_SCREEN, null, details);
            }
        }

        _handleShowStatePassed() {
            var result = super._handleShowStatePassed(...arguments);

            this._checkTitlebar();

            return result;
        }

        // Titlebar
        _checkTitlebar() {
            if (this.currentView) {
                this.setTitle(this.titleBarText());
                this.setSubtitle(this.titleBarSubtitle());
                this.screenButtons.forEach(function (button) {
                    this.removeSubview(button);
                }.bind(this));

                this._handleComfortModeScreenButtons();
            }
        }

        /**
         * E.g. when opening a source (playlist) from a queue, the detailedContentViewController will be shown as modal.
         * Hence the titleBar needs to be adopted -> this helper fn makes it easier.
         * @returns {boolean}
         * @private
         */
        _isPresentedModal() {
            return this.ViewController instanceof GUI.ModalViewController;
        }

    }

    GUI.AudioZoneV2ControlDetailedContentViewController = AudioZoneV2ControlDetailedContentViewController;
    return GUI;
}(GUI);
