'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.AUDIO_ZONE = "AudioZoneControlStateCell";

    class AudioZoneControlStateCell extends GUI.TableViewV2.Cells.ControlStateCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.playPauseButton = new Controls.AudioZoneControl.PlayPauseButton(this.control);
                this.playPauseButton.element.addClass("content__play-pause-button");
                this.appendSubview(this.playPauseButton, this.contentPlaceholder);
            }.bind(this));
        }

        getStateIDs() {
            var stateIds = super.getStateIDs(...arguments);
            stateIds.push("isSynced", "syncColor");
            return stateIds;
        }

        receivedStates(states) {
            // don't call the base, no need for it
            if (this.__isSynced !== states.isSynced) {
                this.__isSynced = states.isSynced;
                this.setRightIcon(states.isSynced ? Icon.AudioZone.GROUPED : null).then(function () {
                    this.setRightIconColor(states.isSynced ? states.syncColor : null);
                }.bind(this));
            }
        }

    }

    GUI.TableViewV2.Cells.AudioZoneControlStateCell = AudioZoneControlStateCell;
    return GUI;
}(window.GUI || {});
