'use strict';

window.GUI = function (GUI) {
    class AudioZoneControlDetailedViewController extends GUI.ViewController {
        constructor(details) {
            super($('<div/>'));
            Object.assign(this, ContextMenuHandler.Mixin);
            this.showStateArgs = details.showStateArgs;
            this.control = MediaServerComp.getActiveZoneControl();
            this.legacySearchIdentifier = null;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(() => {
                !HD_APP && this.setUpComfortUI();
            });
        }

        viewWillAppear() {
            var args = arguments,
                prms = [true];

            if (!this.didAlreadyShowState && this.showStateArgs) {
                // add a dummy screen so that everything works correctly (HD_OVERLAY animation..)
                // Account for the dummy screen when navigatingBackToRoot...
                prms.push(super.showState("GUI.Screen", null, $("<div>"), AnimationType.NONE));
                prms.push(super.showState(...this.showStateArgs));
                this.didAlreadyShowState = true;
            }

            return Q.all(prms).then(() => {
                this.rightButton && this.toggleSubview(this.rightButton, MediaServerComp.Feature.V2_FIRMWARE || this.currentView && this.currentView.legacySearchIdentifier);
                return super.viewWillAppear(...args);
            });
        }

        showState(identifier) {
            var handleInMaster = false;

            switch (identifier) {
                case Controls.AudioZoneControl.Enums.ScreenState.FAVORITE_EDIT:
                case Controls.AudioZoneControl.Enums.ScreenState.GLOBAL_FAVORITE_EDIT:
                case Controls.AudioZoneControl.Enums.ScreenState.SETTINGS_SHORTCUT_EDIT:
                case Controls.AudioZoneControl.Enums.ScreenState.CONTEXT_MENU_BASE:
                case Controls.AudioZoneControl.Enums.ScreenState.ADD_MEDIA_VC_BASE:
                case Controls.AudioZoneControl.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST:
                case Controls.AudioZoneControl.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST_PICKER:
                case Controls.AudioZoneControl.Enums.ScreenState.ADD_MEDIA_VC_PLAYLIST_SPOTIFY:
                case Controls.AudioZoneControl.Enums.ScreenState.ADD_MEDIA_VC_FAVS:
                case Controls.AudioZoneControl.Enums.ScreenState.ADD_ZONE_FAV:
                case Controls.AudioZoneControl.Enums.ScreenState.EDIT_MEDIA:
                    handleInMaster = true;
                    break;}


            if (handleInMaster) {
                return this.ViewController.showState.apply(this.ViewController, arguments);
            } else {
                return super.showState(...arguments);
            }
        }

        navigateBackToRoot() {
            return super.navigateBackToRoot(1); // Account for the dummy view
        }

        navigateBack() {
            // check if we are HD and did show a dummy view!
            if (this.history.length === 2 && !(this.ViewController instanceof GUI.ModalViewController)) {
                this.dismiss();
            } else {
                return super.navigateBack(...arguments);
            }
        }

        dismiss() {
            if (HD_APP) {
                return this.ViewController.dismiss();
            } else {
                return super.dismiss(...arguments);
            }
        }

        titleBarText() {
            if (this.currentView) {
                return this.currentView.viewControllerTitleText && this.currentView.viewControllerTitleText();
            } else {
                return super.titleBarText(...arguments);
            }
        }

        titleBarSubtitle() {
            if (this.currentView) {
                return this.currentView.viewControllerSubtitleText && this.currentView.viewControllerSubtitleText();
            } else {
                return null;
            }
        }

        // Increase the tap area by also forward a tap on the title to the subtitle
        onTitleTapped() {
            this.onSubtitleTapped.apply(this, arguments);
        }

        onSubtitleTapped() {
            if (this.handleOnSubtitleTapped) {
                this.handleOnSubtitleTapped.apply(this, arguments);
            }
        }

        getLeftIcon() {
            return Icon.Buttons.HOME;
        }

        leftAction() {
            this.dismiss();
        }

        getRightIcon() {
            return Icon.Buttons.SEARCH;
        }

        rightAction() {
            var searchScreenState,
                details = {
                    control: this.control,
                    delegate: this.currentView
                };

            if (MediaServerComp.isRestricted()) {
                MediaServerComp.showRestrictedPopup();
            } else {
                if (MediaServerComp.Feature.V2_FIRMWARE) {
                    searchScreenState = Controls.AudioZoneControl.Enums.ScreenState.GLOBAL_SEARCH_SCREEN;
                } else {
                    searchScreenState = Controls.AudioZoneControl.Enums.ScreenState.LEGACY_SEARCH_SCREEN;
                }

                this.ViewController.showState(searchScreenState, null, details);
            }
        }

        _handleShowStatePassed() {
            var result = super._handleShowStatePassed(...arguments);

            this._checkTitlebar();

            return result;
        }

        // Titlebar
        _checkTitlebar() {
            if (this.currentView && !HD_APP) {
                this.setTitle(this.titleBarText());
                this.setSubtitle(this.titleBarSubtitle());
                this.element.addClass("audio-zone-control-detailed-view-controller--with-title-bar");
            }
        }

    }

    GUI.AudioZoneControlDetailedViewController = AudioZoneControlDetailedViewController;
    return GUI;
}(window.GUI || {});
