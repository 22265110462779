'use strict'; // This control is used in Light(V2)Control, don't delete it!

{//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var BRIGHTNESS_STEP = 1;

    class ColorPickerV2Control extends Controls.ControlBase {
        //region Static
        static Template = function () {
            var getHeader = function getHeader(controlName, isMaster) {
                var bottom = "";

                if (!isMaster) {
                    bottom = '' + '   <div class="container__bottom">' + '       ' + getStepButton(false) + '           <div class="bottom-slider"></div>' + '       ' + getStepButton(true) + '   </div>';
                }

                return $('<div class="content__container">' + '   <div class="container__top">' + '       <div class="top__label-container">' + '           <div class="label-container__title">' + '               <div class="title__name-label text-overflow-ellipsis">' + controlName + '</div>' + '               ' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon') + '           </div>' + '       </div>' + '       <div class="top__control-area">' + '           <div class="control-area__value-label text-overflow-ellipsis">&nbsp;</div>' + '           <div class="control-area__color-indicator">' + ImageBox.getResourceImageWithClasses(Icon.ColorPickerV2.SYMBOL, "color-indicator__symbol") + '</div>' + '       </div>' + '   </div>' + bottom + '</div>');
            };

            var getStepButton = function getStepButton(isInc) {
                var imgSrc = isInc ? Icon.ColorPickerV2.Brightness.UP : Icon.ColorPickerV2.Brightness.DOWN;
                return '<div class="bottom__btn-touch-area button--' + (isInc ? "inc" : "dec") + '">' + ImageBox.getResourceImageWithClasses(imgSrc, "btn-touch-area__btn clickable") + '</div>';
            };

            return {
                getHeader: getHeader
            };
        }(); //endregion Static

        constructor(delegate, dataSource, control, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            this.color = {
                hue: 0,
                sat: 0
            };
            this.states = {};
            this.isMaster = !!control.details.isMaster;
        }

        contentForCell() {
            return {
                visuType: Controls.VisuType.CONTROL_CONTENT,
                useBaseHeader: false
            };
        }


        initHeader() {
            let prms = [],
                headerContent = ColorPickerV2Control.Template.getHeader(this.control.name, this.isMaster); // required to show/hide the disclosure icon via baseClass

            this.headerElements.disclosureIcon = headerContent.find('.title__disclosure-icon');
            this.headerElements.titleLabel = headerContent.find('.title__name-label');
            this.headerElements.valueLabel = headerContent.find(".control-area__value-label");
            this.headerElements.colorIndicator = headerContent.find('.control-area__color-indicator');

            if (!this.isMaster) {
                this.colorView = new GUI.ColorView(GUI.ColorViewType.BALL);
                this.prependSubview(this.colorView, this.headerElements.colorIndicator);
                this.colorView.getElement().addClass("color-indicator__color-view");
                this.buttons = {
                    btnDec: new GUI.LxButton(this, headerContent.find(".button--dec")[0], Color.BUTTON_GLOW, null, true, 0),
                    btnInc: new GUI.LxButton(this, headerContent.find(".button--inc")[0], Color.BUTTON_GLOW, null, true, 0)
                };
                this.buttons.btnDec.useChildsAsActiveParts("fill");
                this.buttons.btnInc.useChildsAsActiveParts("fill");
                prms.push(this.appendReactComp({
                    reactComp: GUI.LxSlider,
                    compProps: {
                        hasGradientTrack: true,
                        hasCustomRate: true,
                        antiGhostTimer: true,
                        min: 0,
                        max: 100,
                        step: BRIGHTNESS_STEP,
                        updateRate: 0.25
                    },
                    target: headerContent.find(".bottom-slider")
                }).then(instance => this.slider = instance));
                this.addToHandledSubviews(this.buttons.btnDec);
                this.addToHandledSubviews(this.buttons.btnInc);
            } else {
                this.headerElements.colorIndicator.hide();
            }

            return Q.all(prms).then(() => {
                this.updateHeader(this.control.getStates());
                return headerContent
            });
        }

        viewDidLoad() {
            var promise = super.viewDidLoad(...arguments);
            this.isMaster && this.contentPlaceholder.addClass("cell__content--master");
            return promise;
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            if (!this.isMaster) {
                this.slider.onPositionChanged = this._sendValue.bind(this);
                this.buttons.btnDec.onButtonHit = this._decreaseValue.bind(this);
                this.buttons.btnDec.onButtonTicked = this._decreaseValue.bind(this);
                this.buttons.btnInc.onButtonHit = this._increaseValue.bind(this);
                this.buttons.btnInc.onButtonTicked = this._increaseValue.bind(this);
            }

            return promise;
        }

        viewDidDisappear() {
            if (!this.isMaster) {
                this.slider.onPositionChanged = null;
                this.buttons.btnDec.onButtonHit = null;
                this.buttons.btnDec.onButtonTicked = null;
                this.buttons.btnInc.onButtonHit = null;
                this.buttons.btnInc.onButtonTicked = null;
            }

            return super.viewDidDisappear(...arguments);
        }

        _hammer() {
            if (!this._hammerObject) {
                let containerTopElement = this.clickableContainer.find(".container__top")[0];
                if (containerTopElement) {
                    this._hammerObject = Hammer(containerTopElement, this._getHammerOptions()); // make only the content__top part clickable
                } else {
                    this._hammerObject = Hammer(this.clickableContainer[0], this._getHammerOptions());
                }
            }

            return this._hammerObject;
        }

        _updateDiscIcon() {
            var clickable = this.clickableAllowed();
            this.headerElements.disclosureIcon.toggle(clickable);
            this.contentPlaceholder.toggleClass("clickable", false);
            let containerTopElement = this.contentPlaceholder.find(".container__top");
            containerTopElement.toggleClass("clickable", clickable);
        }

        rippleAllowed() {
            return false;
        }

        getStatusElements() {
            return {
                single: [this.headerElements.colorIndicator]
            };
        }

        updateHeader(states) {
            if (this.isMaster) {
                this.headerElements.valueLabel.text("");
            } else {
                this.colorView.setColors(states.activeColors);
                this.headerElements.valueLabel.text(lxFormat("<v.0> %", states.activeBrightness));
                this.slider.setPosition(states.activeBrightness);

                if (this.states.sequenceActive) {
                    this.slider.setActiveColors([Color.STATE_INACTIVE, Color.Dimmer.ON]);
                } else {
                    var color = cloneObject(states.color);
                    color.brightness = 100;
                    this.slider.setActiveColors([Color.STATE_INACTIVE, LxColorUtils.getCSSRGBPropertyFromColorObject(color)]);
                }
            }
        }

        // Private methods

        /**
         * Will send the brightness value to the Miniserver
         * @param pos           the updated brightness to send.
         * @param isOff         not used, but the slider provides it.
         * @param isDragging    true if the slider is being dragged, if so the cmd should not be recorded
         * @private
         */
        _sendValue(pos, isOff, isDragging) {
            var cmd, ignoreCmd; // avoid sending too many unnecessary requests while the slider is being dragged. (e.g. the value remains unchanged)

            ignoreCmd = pos === this.states.activeBrightness && isDragging;

            if (!ignoreCmd) {
                if (this.states.sequenceActive || this.control.isInDayLightMode()) {
                    cmd = Commands.format(Commands.COLOR_PICKER_V2.SET_BRIGHTNESS, pos);
                } else {
                    this.states.color.brightness = pos;
                    cmd = LxColorUtils.getStringFromColorObject(this.states.color);
                }

                this.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging);
            }
        }

        _decreaseValue() {
            var newVal = Math.max(this.states.activeBrightness - BRIGHTNESS_STEP, 0);

            this._sendValue(newVal);

            this.slider.resetAntiGhost();
        }

        _increaseValue() {
            var newVal = Math.min(this.states.activeBrightness + BRIGHTNESS_STEP, 100);

            this._sendValue(newVal);

            this.slider.resetAntiGhost();
        }

    }

    Controls.ColorPickerV2Control = ColorPickerV2Control;
}
