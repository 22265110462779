'use strict';

window.GUI = function (GUI) {
    class AudioZoneV2ControlMiniPlayer extends GUI.ViewController {
        //region Static
        static Template = class {
            static getPlayer() {
                if (HD_APP) {
                    return $("<div class='audio-zone-v2-control-mini-player'>" + "   " + this.getPlayerDetails() + "</div>");
                } else {
                    return $("<div class='audio-zone-v2-control-mini-player'>" + "   " + this.getPlayerDetails() + "   " + "<div class='control-strip-placeholder'></div>" + "</div>");
                }
            }
            static getPlayerDetails() {
                if (HD_APP) {
                    return "" + "<div class='audio-zone-v2-control-mini-player__player-details'>" + "   " + "<div class='player-details__left-container player-button'>" + "       " + "<div class='player-details__cover-placeholder'/>" + "       " + "<div class='player-details__text-placeholder'>" + "           " + "<div class='player-details__title text-overflow-ellipsis'/>" + "           " + "<div class='player-details__subtitle text-overflow-ellipsis'/>" + "       " + "</div>" +
                        /*'           <div class="player-details__ctx-button">' +
                        '               ' + ImageBox.getResourceImageWithClasses(Icon.Buttons.MORE_HORIZONTAL, "ctx__icon") +
                        '           </div>' +*/
                        "   " + "</div>" + "   " + "<div class='player-details__action-container'>" + "       " + "<div class='action-container__prev-button-placeholder'>" + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PREV, "button-placeholder__icon button-placeholder__icon--prev") + "       " + "</div>" + "       " + "<div class='action-container__play-pause-placeholder action-container__play-pause-placeholder--play-state--0'>" + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "button-placeholder__icon play-state--1") + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "button-placeholder__icon play-state--0") + "       " + "</div>" + "       " + "<div class='action-container__next-button-placeholder'>" + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "button-placeholder__icon button-placeholder__icon--next") + "       " + "</div>" + "   " + "</div>" + "   " + "<div class='control-strip-placeholder'></div>" + "</div>";
                } else {
                    return "" + "<div class='audio-zone-v2-control-mini-player__player-details player-button'>" + "   " + "<div class='player-details__cover-placeholder'/>" + "   " + "<div class='player-details__title text-overflow-ellipsis'/>" + "   " + "<div class='player-details__action-container'>" + "       " + "<div class='action-container__play-pause-placeholder action-container__play-pause-placeholder--play-state--0'>" + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "button-placeholder__icon play-state--1") + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "button-placeholder__icon play-state--0") + "       " + "</div>" + "       " + "<div class='action-container__next-button-placeholder'>" + "          " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.NEXT, "button-placeholder__icon button-placeholder__icon--next") + "       " + "</div>" + "   " + "</div>" + "</div>";
                }
            }
        } //endregion Static

        //region Private
        //fast-class-es6-converter: extracted from defineStatic({}) content
        BUTTON_KEY = {
            THIS: "miniPlayer",
            PLAY_PAUSE: "play/pause",
            PREV: "prev",
            NEXT: "next"
        }; //endregion Private

        //region Getter
        get identifier() {
            var identifier;

            switch (this.states.contentType) {
                case MusicServerEnum.MediaContentType.SERVICE:
                    if ((this.states.id || this.states.audiopath).indexOf("spotify") !== -1) {
                        identifier = MusicServerEnum.Service.SPOTIFY;
                    } else {
                        identifier = MusicServerEnum.Service.LOCAL;
                    }

                    break;

                case MusicServerEnum.MediaContentType.LIBRARY:
                    identifier = MusicServerEnum.Service.LMS;
                    break;
            }

            return identifier;
        }

        get username() {
            var user = MusicServerEnum.NOUSER;

            switch (this.states.contentType) {
                case MusicServerEnum.MediaContentType.SERVICE:
                    if ((this.states.id || this.states.audiopath).indexOf("spotify") !== -1) {
                        user = Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.getUserUnique(Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.control).activeUser);
                    }

                    break;
            }

            return user;
        } //endregion Getter


        constructor(control) {
            super(AudioZoneV2ControlMiniPlayer.Template.getPlayer());
            applyMixins(this, StateHandler.Mixin, MediaContextMenuHandlerV2.Mixin);
            this.control = control;
            this.buttons = {};
        }

        getStateIDs() {
            return ["volume", "texts", "isPlaying", MusicServerEnum.Event.DURATION, MusicServerEnum.Event.TIME];
        }

        viewDidLoad() {
            this.elements = this.elements || {};
            this.elements.coverPlaceholderElm = this.element.find(".player-details__cover-placeholder");
            this.elements.songTitleElm = this.element.find(".player-details__title");

            if (HD_APP) {
                this.elements.prevBtnElm = this.element.find(".action-container__prev-button-placeholder");
                this.elements.songSubtitleElm = this.element.find(".player-details__subtitle");

                //TODO adjust height of miniplayer so slider is not cut
                this.prependReactComp({
                    reactComp: GUI.LxSlider,
                    compProps: {
                        activeColor: window.Styles.colors.stateActive,
                        thumbStyle: {display: "none"}
                }
                }).then(instance => {
                    this.timeLineSlider = instance;
                    this.timeLineSlider.onPositionChanged = this._handleTimeLineSlider.bind(this);
                    this.timeLineSlider.onDraggingChanged = (value, isDragging) => this._handleTimeLineSlider(value, false, isDragging)
                })
            }

            this.elements.playStateBtnElm = this.element.find(".action-container__play-pause-placeholder");
            this.elements.nextBtnElm = this.element.find(".action-container__next-button-placeholder");
            this.coverView = new Controls.AudioZoneV2Control.CurrentCoverViewV2(this.control);
            this.appendSubview(this.coverView, this.elements.coverPlaceholderElm);
            this.controlStrip = new GUI.AudioZoneV2ControlStrip(this.control, { isInAmbientMode: this.isInAmbientMode() });
            this.appendSubview(this.controlStrip, this.element.find(".control-strip-placeholder"));
            this.buttons[this.BUTTON_KEY.THIS] = new GUI.LxButton(this, this.element.find(".player-button"), Color.BUTTON_GLOW);
            this.buttons[this.BUTTON_KEY.PLAY_PAUSE] = new GUI.LxButton(this, this.elements.playStateBtnElm, Color.BUTTON_GLOW);
            this.elements.prevBtnElm && (this.buttons[this.BUTTON_KEY.PREV] = new GUI.LxButton(this, this.elements.prevBtnElm, Color.BUTTON_GLOW));
            this.buttons[this.BUTTON_KEY.NEXT] = new GUI.LxButton(this, this.elements.nextBtnElm, Color.BUTTON_GLOW);
            Object.keys(this.buttons).forEach(function (buttonKey) {
                this.addToHandledSubviews(this.buttons[buttonKey]);
                this.buttons[buttonKey].onButtonHit = this._handleButtonHit.bind(this, buttonKey);
                this.buttons[buttonKey].onButtonTapped = this._handleButtonTapped.bind(this, buttonKey);
                this.buttons[buttonKey].onButtonReleased = this._handleButtonReleased.bind(this, buttonKey);
                this.buttons[buttonKey].onButtonTicked = this._handleButtonTicked.bind(this, buttonKey);
            }.bind(this));
            return super.viewDidLoad(...arguments).then(function () {
                this._requestStates();
            }.bind(this));
        }

        applyDragRegion() {// We don't want that here!
        }

        setViewController(vc) {
            super.setViewController(vc);
            this.element.removeClass("uses-react-safe-area")
        }

        viewWillAppear() {
            $(document.body).addClass("audio-zone-v2-appeared");
            this.element.toggleClass("ambient-mode-active", this.isInAmbientMode())
            return super.viewWillAppear(...arguments).then(function () {
                this._registerForStates(this.control.uuidAction, this.getStateIDs());
            }.bind(this));
        }

        viewWillDisappear() {
            this._unregisterStates();
            return super.viewWillDisappear(...arguments);
        }

        destroy() {
            $(document.body).removeClass("audio-zone-v2-appeared");
            super.destroy();
        }

        receivedStates(states) {
            this.states = states;
            this.elements.playStateBtnElm.toggleClass("action-container__play-pause-placeholder--play-state--0", !!Number(!states.isPlaying));
            this.elements.playStateBtnElm.toggleClass("action-container__play-pause-placeholder--play-state--1", !!Number(states.isPlaying));
            this.elements.songTitleElm.text(states.texts.mainText);
            this.elements.songTitleElm.prop("title", states.texts.mainText);

            if (this.elements.songSubtitleElm) {
                this.elements.songSubtitleElm.text(states.texts.subText || "");
                this.elements.songSubtitleElm.prop("title", states.texts.subText || "");
            }

            if (this.timeLineSlider) {
                this.timeLineSlider.setColor(this.states.isPlaying ? window.Styles.colors.stateActive : Color.WHITE); // white if nothing is playing
                this.timeLineSlider.setPosition(this.states[MusicServerEnum.Event.TIME_INTERNAL]);

                this.timeLineSlider.updateProperties({
                    max: this.states[MusicServerEnum.Event.DURATION_INTERNAL],
                    hidden: states.isStream // a react component cannot be hidden using toggleSubview
                });
            }

            this.buttons[this.BUTTON_KEY.PREV] && this.toggleSubview(this.buttons[this.BUTTON_KEY.PREV], !states.isStream);
            this.buttons[this.BUTTON_KEY.NEXT] && this.toggleSubview(this.buttons[this.BUTTON_KEY.NEXT], !states.isStream);
        }

        _handleButtonHit(buttonKey) {
            switch (buttonKey) {
                case this.BUTTON_KEY.THIS:
                    this._ctxMenuTimeout = setTimeout(function () {
                        this._showContextMenuForItem(this.states);
                    }.bind(this), 1000);
                    break;
            }
        }

        _handleButtonTapped(buttonKey) {
            switch (buttonKey) {
                case this.BUTTON_KEY.THIS:
                    this.ViewController.showState(Controls.AudioZoneV2Control.Enums.ScreenState.PLAYER, null, {
                        control: this.control,
                        showQueue: HD_APP
                    }, null, true);
                    break;

                case this.BUTTON_KEY.PLAY_PAUSE:
                    if (this.states.isPlaying) {
                        this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MusicServerEnum.AudioCommands.PAUSE
                        });
                    } else {
                        this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                            cmd: MusicServerEnum.AudioCommands.PLAY
                        });
                    }

                    break;

                case this.BUTTON_KEY.PREV:
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.QUEUE.PREV
                    });
                    break;

                case this.BUTTON_KEY.NEXT:
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.QUEUE.NEXT
                    });
                    break;
            }
        }

        _handleButtonTicked(buttonKey) {
            switch (buttonKey) {
                case this.BUTTON_KEY.VOL_DOWN:
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.VOLUME.DOWN_BY + this.states.volumeStep
                    });
                    break;

                case this.BUTTON_KEY.VOL_UP:
                    this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                        cmd: MusicServerEnum.AudioCommands.VOLUME.UP_BY + this.states.volumeStep
                    });
                    break;
            }
        }

        _handleButtonReleased(buttonKey) {
            switch (buttonKey) {
                case this.BUTTON_KEY.THIS:
                    clearTimeout(this._ctxMenuTimeout);
                    this._ctxMenuTimeout = null;
                    break;
            }
        }

        _handleTimeLineSlider(progress, isMin, isDragging) {
            if (isDragging) {
                return; // ignore the event when dragging did start
            }
            var value = progress;

            if (Math.round(value) === Math.round(this.states[MediaEnum.Event.DURATION])) {
                value = value - 1;
            }

            this.control.audioserverComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: MusicServerEnum.AudioCommands.SEEK.TO + value
            });
        }

        // -------------- ContextButtonMixin ----------------------
        getMediaInfo() {
            return {
                service: {
                    uid: this.identifier + "/" + this.username,
                    cmd: (this.states.audiopath || this.states.id).replace(/(^spotify)@.*$/, "$1")
                }
            };
        }

    }

    GUI.AudioZoneV2ControlMiniPlayerViewController = AudioZoneV2ControlMiniPlayer;
    return GUI;
}(window.GUI || {});
