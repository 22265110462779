'use strict'; // This control is used in Light(V2)Control, don't delete it!

class ColorPickerControl extends Controls.ControlBase {
    //region Static
    static Template = function () {
        var getHeader = function getHeader() {
            return $('<div class="control-area__color-indicator"></div>' + '<div class="control-area__switch-touch-area"></div>');
        };

        return {
            getHeader: getHeader
        };
    }(); //endregion Static

    constructor(delegate, dataSource, control, sectionIdx, rowIdx, tableView) {
        super(...arguments); // initial values when the user turn on the lights with the brightness slider

        if (this.control.details.pickerType === 'Lumitech') {
            this.colorObject = {
                kelvin: 4000
            };
        } else {
            this.colorObject = {
                hue: 0,
                sat: 0
            };
        }
    }

    contentForCell() {
        return {
            visuType: Controls.VisuType.CONTROL_CONTENT,
            useBaseHeader: true
        };
    }

    initHeader() {
        var headerContent = ColorPickerControl.Template.getHeader(this.control);
        this.headerElements.colorIndicator = headerContent.closest('.control-area__color-indicator');
        this.powerSwitch = new GUI.LxSwitch(this, headerContent[1]);
        return headerContent;
    }

    getStatusElements() {
        return {
            single: [this.headerElements.colorIndicator]
        };
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        this.powerSwitch.onStateChanged = this._togglePower.bind(this);
    }

    viewDidDisappear() {
        this.powerSwitch.onStateChanged = null;
        super.viewDidDisappear(...arguments);
    }

    destroy() {
        this.powerSwitch.destroy();
        super.destroy();
    }

    updateHeader(states) {
        this.headerElements.colorIndicator.css('background-color', LxColorUtils.getCSSRGBPropertyFromColorObject(states.colorObject));
        var darkMode = NavigationComp.getDarkModeState();
        this.headerElements.colorIndicator.toggleClass("color-indicator--light-bg", !darkMode && states.colorObject.sat < Controls.LightControl.SATURATION_MIN_FOR_BG_A);
        this.headerElements.colorIndicator.toggleClass("color-indicator--dark-bg", darkMode && states.colorObject.brightness < Controls.LightControl.BRIGHTNESS_MIN_FOR_BG_B);
        this.powerSwitch.setActive(states.powerOn);
    }

    // Private methods
    _togglePower(state) {
        this.sendCommand(state ? Commands.COLOR_PICKER.ON : Commands.COLOR_PICKER.OFF);
    }

}

Controls.ColorPickerControl = ColorPickerControl;
