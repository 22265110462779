'use strict'; // This control is used in Light(V2)Control, don't delete it!

class DimmerControl extends Controls.ControlBase {
    //region Static
    static Template = function () {
        var getHeader = function getHeader(controlName, isLightV2Dimmer) {
            var controlArea = ''; // a dimmer inside V2 has a different control area.

            if (isLightV2Dimmer) {
                controlArea = '<div class="control-area__preview control-area__item">' + ImageBox.getResourceImageWithClasses(Icon.Dimmer.SYMBOL, "preview__icon preview__item") + '</div>';
            } else {
                controlArea = '<div class="control-area__switch control-area__item"></div>';
            }

            return $('<div class="content__container">' + '   <div class="container__top">' + '       <div class="top__label-container">' + '           <div class="label-container__title">' + '               <div class="title__name-label text-overflow-ellipsis">' + controlName + '</div>' + '               ' + ImageBox.getResourceImageWithClasses('resources/Images/General/disclosure-icon.svg', 'title__disclosure-icon') + '           </div>' + '       </div>' + '       <div class="top__control-area">' + '           <div class="control-area__value-label text-overflow-ellipsis"></div>' + controlArea + '       </div>' + '   </div>' + '   <div class="container__bottom">' + '       ' + getStepButton(false) + '           <div class="bottom-slider"></div>' + '       ' + getStepButton(true) + '   </div>' + '</div>');
        };

        var getStepButton = function getStepButton(isInc) {
            var imgSrc = isInc ? 'resources/Images/Controls/LightsceneRGB/icon-brightness_up.svg' : 'resources/Images/Controls/LightsceneRGB/icon-brightness_down.svg';
            return '<div class="bottom__btn-touch-area button--' + (isInc ? "inc" : "dec") + '">' + ImageBox.getResourceImageWithClasses(imgSrc, "btn-touch-area__btn clickable") + '</div>';
        };

        return {
            getHeader: getHeader
        };
    }(); //endregion Static

    constructor(delegate, dataSource, control, sectionIdx, rowIdx, tableView) {
        super(...arguments);
        this.buttons = {};
    }

    contentForCell(cell, section, row, tableView) {
        if (cell === this) {
            return {
                visuType: Controls.VisuType.CELL_ONLY,
                useBaseHeader: false
            };
        } else {
            return super.contentForCell(...arguments);
        }
    }

    initHeader() {
        let prms = [],
            isV2 = this._isLightV2Dimmer(),
            headerContent = DimmerControl.Template.getHeader(this.control.name, isV2),
            gradientColors = null; // required to show/hide the disclosure icon via baseClass

        this.headerElements.disclosureIcon = headerContent.find('.title__disclosure-icon');
        this.headerElements.titleLabel = headerContent.find('.title__name-label');
        this.headerElements.valueLabel = headerContent.find(".control-area__value-label");
        this.buttons = {
            btnDec: new GUI.LxButton(this, headerContent.find(".button--dec")[0], Color.BUTTON_GLOW, null, true, 0),
            btnInc: new GUI.LxButton(this, headerContent.find(".button--inc")[0], Color.BUTTON_GLOW, null, true, 0)
        };
        this.buttons.btnDec.useChildsAsActiveParts("fill");
        this.buttons.btnInc.useChildsAsActiveParts("fill");
        this.addToHandledSubviews(this.buttons.btnDec);
        this.addToHandledSubviews(this.buttons.btnInc); // control area.

        if (isV2) {
            this.headerElements.preview = headerContent.find(".control-area__preview");
            this.colorView = new GUI.ColorView(GUI.ColorViewType.BALL);
            this.prependSubview(this.colorView, this.headerElements.preview);
            this.colorView.getElement().addClass("preview__color-view  preview__item");
            gradientColors = [Color.STATE_INACTIVE, Color.Dimmer.ON];
        } else {
            this.headerElements.onOfSwitch = headerContent.find(".control-area__switch");
            this.onOffSwitch = new GUI.LxSwitch(this, this.headerElements.onOfSwitch[0]);
        }
        prms.push(this.appendReactComp({
            reactComp: GUI.LxSlider,
            compProps: {
                hasCustomRate: true,
                hasGradientTrack: true,
                antiGhostTimer: true,
                activeColors: gradientColors,
                updateRate: 0.25,
                value: this._getValueForSlider(),
                min: 0,
                max: 100,
                step: 1
            },
            target: headerContent.find(".bottom-slider")
        }).then(instance => this.slider = instance));

        return Q.all(prms).then(() => {
            this.updateHeader(this.control.getStates());
            return headerContent
        });
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);

        if (this.onOffSwitch) {
            this.onOffSwitch.onStateChanged = this._togglePower.bind(this);
        }

        this.slider.onPositionChanged = this._sliderPositionChanged.bind(this);
        this.buttons.btnDec.onButtonHit = this._decreaseValue.bind(this);
        this.buttons.btnDec.onButtonTicked = this._decreaseValue.bind(this);
        this.buttons.btnInc.onButtonHit = this._increaseValue.bind(this);
        this.buttons.btnInc.onButtonTicked = this._increaseValue.bind(this);
    }

    getStatusElements() {
        var res = {
            singleRight: []
        };

        if (this._isLightV2Dimmer()) {
            res.singleRight.push(this.headerElements.preview);
        } else {
            res.singleRight.push(this.headerElements.onOfSwitch);
        }

        return res;
    }

    viewDidDisappear() {
        if (this.onOffSwitch) {
            this.onOffSwitch.onStateChanged = null;
        }

        this.slider.onPositionChanged = null;
        this.buttons.btnDec.onButtonHit = null;
        this.buttons.btnDec.onButtonTicked = null;
        this.buttons.btnInc.onButtonHit = null;
        this.buttons.btnInc.onButtonTicked = null;
        super.viewDidDisappear(...arguments);
    }

    destroy() {
        if (this.onOffSwitch) {
            // switch is not a view yet.
            this.onOffSwitch.destroy();
        }

        this.removeSubview(this.slider);
        this.removeSubview(this.buttons.btnDec);
        this.removeSubview(this.buttons.btnInc);
        super.destroy();
    }

    updateHeader(states) {
        this.headerElements.valueLabel.text(states.stateText); // the UI always should display 0 - 100%, so use these values

        var props = {
            min: 0,
            max: 100,
            step: states.step
        };
        this.slider.updateProperties(props);
        this.slider.setPosition(this._getValueForSlider(states));
        this.onOffSwitch && this.onOffSwitch.setActive(states.isOn);
        this.colorView && this.colorView.setColors([states.color]);
    }

    // Private methods
    _getValueForSlider(states) {
        var intStates = states || this.control.getStates(),
            value = 0;

        if (intStates) {
            value = map(intStates.pos, [intStates.min, intStates.max], [0, 100]);
        }

        return value;
    }

    /**
     * Returns true if this dimmer is used inside a lightcontrol V2, which effects the UI.
     * @return {*|boolean}
     * @private
     */
    _isLightV2Dimmer() {
        return this.control.uuidParent && ActiveMSComponent.getStructureManager().getControlByUUID(this.control.uuidParent).controlType === 'LightV2';
    }

    _sendValue(pos, isOff, isDragging) {
        Debug.Control.Dimmer && console.log(this.name, "_sendValue: " + pos + " dragging: " + isDragging); // avoid sending requests that are unneccessary (e.g. the same value while dragging). If not dragging, always send it.

        var ignoreCmd = pos === this.states.pos && isDragging;

        if (!ignoreCmd) {
            // forward the isDragging to the sendCommand --> there it should block recording while the slider is dragging.
            this.sendCommand(Commands.format(Commands.DIMMER.VALUE, pos), Commands.Type.OVERRIDE, null, isDragging);
        }
    }

    _togglePower(isOn) {
        Debug.Control.Dimmer && console.log(this.name, "_togglePower: " + isOn); // to enable state updates again

        this.slider.resetAntiGhost();

        if (isOn !== this.states.isOn) {
            this.sendCommand(isOn ? Commands.DIMMER.ON : Commands.DIMMER.OFF, Commands.Type.OVERRIDE);
        }
    }

    _decreaseValue() {
        Debug.Control.Dimmer && console.log(this.name, "_decreaseValue: -" + this.states.step);
        var newVal = this.states.pos - this.states.step;

        if (newVal < this.states.min) {
            this._togglePower(false);
        } else {
            this._sendValue(newVal);
        }

        this.slider.resetAntiGhost();
    }

    _increaseValue() {
        Debug.Control.Dimmer && console.log(this.name, "_increaseValue: +" + this.states.step);

        if (this.states.pos === 0 && this.states.min > 0) {
            // is off -> switch on to minimum
            this._sendValue(this.states.min);
        } else {
            var newVal = this.states.pos + this.states.step;

            if (newVal > this.states.max) {
                newVal = this.states.max;
            }

            this._sendValue(newVal);
        }

        this.slider.resetAntiGhost();
    }

    _sliderPositionChanged(value) {
        // Only map the value if the value is not 0, this ensures that every slider, especially 1-10V,
        // can be switched off with the slider
        if (value !== 0) {
            value = map(value, [0, 100], [this.states.min, this.states.max]);
        }

        this._sendValue(value);
    }

}

Controls.DimmerControl = DimmerControl;
