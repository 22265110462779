'use strict';

var GUI = function (GUI) {
    class AudioZoneV2GroupVolumeView extends GUI.View {
        //region Static
        static Template = class {
            static getTemplate() {
                return $("<div class='audio-zone-v2-group-volume-view'>" + "</div>");
            }
            static getHeader() {
                return $("<div class='content__title-bar'>" + "<div class='title-bar__top'>" + "<div class='top__text'/>" + "<div class='top__button'/>" + "</div>" + "   <div class='title-bar__master-container'>" + "       <div class='master-container__play-pause-container'>" + "          <div class='play-pause-button-container play-pause-button-container--pause'>" + "             " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PAUSE, "play-pause-button") + "          </div>" + "          <div class='play-pause-button-container play-pause-button-container--play'>" + "             " + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.CONTROL.PLAY, "play-pause-button") + "          </div>" + "       </div>" + "       <div class='master-container-strip-placeholder'></div>" + "  </div>" + "</div>");
            }
        }; //endregion Static

        constructor(control, changeFn, forEco) {
            super(AudioZoneV2GroupVolumeView.Template.getTemplate());
            applyMixins(this, StateHandler.Mixin);
            this.control = control;
            this.changeFn = changeFn;
            this.forEco = !!forEco;

            if (this.forEco) {
                this.element.addClass("audio-zone-v2-group-volume-view--for-eco");
            }
        }

        getName() {
            var name = this.control.getName(true),
                dynGroup;

            if (this._detectIsInDynamicGroup()) {
                dynGroup = this.control.audioserverComp.getGroupOfPlayer(this.control.details.playerid);

                if (dynGroup) {
                    name = this.control.getName() + " + " + (dynGroup.players.length - 1);
                } else {
                    console.warn(this.viewId, "getName - tried to query dynamic group while non exists anymore!");
                }
            }

            return name;
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.elements = {
                    content: this.element,
                    header: AudioZoneV2GroupVolumeView.Template.getHeader()
                };
                this.elements.headerText = this.elements.header.find(".top__text");
                this.elements.groupBtnElem = this.elements.header.find(".top__button");
                this.elements.groupBtnElem.text(_("audio-server.group.edit"));
                this.elements.content.append(this.elements.header);
                this.elements.headerText.text(this.getName());
                this.tableContent = this.getTableContent();
                this.tableViewDataSource = tableViewDataSource(this.tableContent, null, this);
                this.tableViewDelegate = tableViewDelegate(this.tableContent);
                this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
                this.appendSubview(this.tableView, this.elements.content);
                this._tableReadyPromise = this.reload();
                this.pauseButton = new GUI.LxButton(this, this.element.find(".play-pause-button-container--pause"), Color.BUTTON_GLOW, null, true, 250);
                this.pauseButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.pauseButton);
                this.pauseButton.onButtonTapped = this._sendGroupPause.bind(this);
                this.playButton = new GUI.LxButton(this, this.element.find(".play-pause-button-container--play"), Color.BUTTON_GLOW, null, true, 250);
                this.playButton.useChildsAsActiveParts("fill");
                this.addToHandledSubviews(this.playButton);
                this.playButton.onButtonTapped = this._sendGroupPlay.bind(this);
                this.editGroupButton = new GUI.LxButton(this, this.elements.groupBtnElem);
                this.addToHandledSubviews(this.editGroupButton);
                this.editGroupButton.onButtonTapped = this.changeFn;
                this.toggleSubview(this.editGroupButton, this._shouldShowGroupButton());
                this.masterControlStrip = new GUI.AudioZoneV2ControlStrip(this.control, {
                    volumeHud: this,
                    justVolume: true,
                    isMasterVol: true,
                    isInAmbientMode: this.isInAmbientMode()
                });
                this.appendSubview(this.masterControlStrip, this.element.find(".master-container-strip-placeholder"));
            }.bind(this));
        }

        getStateIDs() {
            return [MusicServerEnum.Event.SYNCED_ZONES, MusicServerEnum.Event.MASTER_VOLUME, "isSynced", "syncColor", "syncedzones"];
        }

        viewWillAppear() {
            Debug.Media.DynamicGroups && console.log(this.viewId, "viewWillAppear");
            return Q.all([this._tableReadyPromise, super.viewWillAppear(...arguments)]).then(function (res) {
                this.receivedStates(this.control.getStates());

                this._registerForStates(this.control.uuidAction, this.getStateIDs());

                return res;
            }.bind(this));
        }

        viewDidAppear() {
            Debug.Media.DynamicGroups && console.log(this.viewId, "viewDidAppear");
            return super.viewDidAppear(...arguments).then(function () {
                this._unregisterFromGroupFn = this.control.audioserverComp.registerForGroupingChanges(this._respondToGroupChanges.bind(this));
            }.bind(this));
        }

        viewWillDisappear() {
            Debug.Media.DynamicGroups && console.log(this.viewId, "viewWillDisappear");

            this._unregisterStates();

            this._unregisterFromGroupFn && this._unregisterFromGroupFn();
            this._unregisterFromGroupFn = null;
            return super.viewWillDisappear(...arguments);
        }

        receivedStates(states) {
            Debug.Media.DynamicGroups && console.log(this.viewId, "receivedStates"); // Only reload the table if the grouping changed

            if (this._currentSyncedZones !== JSON.stringify(this._getSyncedControls())) {
                Debug.Media.DynamicGroups && console.log(this.viewId, "   > syncedzones changed!");

                this._respondToGroupChanges();
            } else {
                Debug.Media.DynamicGroups && console.log(this.viewId, "   > syncedzones unchanged! ");
                Debug.Media.DynamicGroups && console.log(this.viewId, "        current: " + this._currentSyncedZones);
                Debug.Media.DynamicGroups && console.log(this.viewId, "      stateCntr: " + JSON.stringify(this._getSyncedControls()));
            }

            this.toggleSubview(this.editGroupButton, this._shouldShowGroupButton());
            this.elements.headerText.text(this.getName());
        }

        getTableContent() {
            var states = this.control.getStates(),
                syncedControls;
            this._currentSyncedZones = JSON.stringify(this._getSyncedControls());
            Debug.Media.DynamicGroups && console.log(this.viewId, "getTableContent - updates currentSyncedZones: " + this._currentSyncedZones);
            syncedControls = this._getSyncedControls();

            if (this.singleOnly) {
                return this._getUngroupedTableContent();
            } else {
                // establish a name map to ensure no duplicate names are shown in this list.
                var nameMap = {},
                    name,
                    room,
                    title;
                syncedControls.forEach(ctrl => {
                    name = ctrl.getName();
                    nameMap[name] = nameMap[name] || 0;
                    nameMap[name] = nameMap[name] + 1;
                });
                return [{
                    rows: syncedControls.map(function (control) {
                        // detect which name should be shown - if more than one with the same name exists, add
                        // room name (if not already used)
                        name = control.getName();
                        room = control.getRoom();

                        if (room && name !== room.name) {
                            title = name + (nameMap[name] > 1 && room ? SEPARATOR_SYMBOL + room.name : "");
                        } else {
                            title = name;
                        }

                        if (control.isConfigured()) {
                            return {
                                content: {
                                    control: control,
                                    title: title,
                                    subtitle: control.getSubtitle(),
                                    isCurrentPlayer: control.uuidAction === this.control.uuidAction
                                },
                                type: GUI.TableViewV2.CellType.Special.VOLUME_OVERLAY_CELL
                            };
                        } else {
                            return {
                                content: {
                                    leftIconSrc: Icon.EMPTY,
                                    title: title,
                                    titleColor: control.uuidAction === this.control.uuidAction ? window.Styles.colors.stateActive : null,
                                    subtitleColor: window.Styles.colors.orange,
                                    subtitle: _("unconfigured.title"),
                                    rightIconSrc: Icon.MESSAGE_CENTER.WARNING,
                                    rightIconColor: window.Styles.colors.orange
                                }
                            };
                        }
                    }.bind(this)).sort(function (a, b) {
                        return a.content.isCurrentPlayer * -1; // Moves the current player on top
                    })
                }];
            }
        }

        reload() {
            var delChanged, dsChanged;
            this.tableContent = this.getTableContent();
            dsChanged = this.tableViewDataSource.update(this.tableContent);
            delChanged = this.tableViewDelegate.update(this.tableContent);
            this.elements.headerText.text(this.getName());
            return dsChanged || delChanged ? this.tableView.reload() : Q.resolve();
        }

        _respondToGroupChanges() {
            Debug.Media.DynamicGroups && console.log(this.viewId, "_respondToGroupChanges");
            this._isInDynamicGroup = this._detectIsInDynamicGroup();
            this.singleOnly = !this.control.isFixedGroup && !this._isInDynamicGroup;
            this.elements.header.toggle(!this.singleOnly);
            this.editGroupButton && this.toggleSubview(this.editGroupButton, this._isInDynamicGroup || this.control.isFixedGroup);
            this.reload();
        }

        /**
         * All zones should start playing
         * Since 2.6 new combined commands are available.
         * @private
         */
        _sendGroupPlay() {
            if (this.control.audioserverComp.Feature.COMBINED_GROUP_COMMANDS) {
                return this._sendCombinedGroupCmd(MusicServerEnum.Commands.GROUP_PLAY);
            } else {
                return this._sendGroupedCmd(MusicServerEnum.AudioCommands.PLAY);
            }
        }

        /**
         * All zones should pause
         * Since 2.6 new combined commands are available.
         * @private
         */
        _sendGroupPause() {
            if (this.control.audioserverComp.Feature.COMBINED_GROUP_COMMANDS) {
                return this._sendCombinedGroupCmd(MusicServerEnum.Commands.GROUP_PAUSE);
            } else {
                return this._sendGroupedCmd(MusicServerEnum.AudioCommands.PAUSE);
            }
        }

        /**
         * The new (since 2.6) grouped play/pause commands need to be sent to each audioserver affected.
         * @param cmd
         * @private
         */
        _sendCombinedGroupCmd(cmd) {
            var playerIdList = [],
                fullCmd,
                audioserverMap = {}; // gather the list of affected players + their audio servers

            this._getSyncedControls().forEach(function (control) {
                audioserverMap[control.audioserverComp.getServerSerialNumber()] = control.audioserverComp;

                if (control.getStates().canPlayMusic) {
                    // don't if locked
                    playerIdList.push(control.details.playerid);
                }
            });

            fullCmd = Commands.format(cmd, playerIdList.join(",")); // send the command to each AS affected.

            Object.values(audioserverMap).forEach(function (as) {
                as.sendMediaServerCommand(fullCmd);
            }.bind(this));
        }

        /**
         * Sends a command to the whole fixed group
         * @param cmd
         * @private
         */
        _sendGroupedCmd(cmd) {
            this._getSyncedControls().forEach(function (control) {
                control.audioserverComp.sendAudioZoneCommand(control.details.playerid, {
                    cmd: cmd
                });
            }.bind(this));
        }

        _getUngroupedTableContent() {
            return [{
                rows: [{
                    content: {
                        control: this.control,
                        title: this.control.getName(),
                        isCurrentPlayer: true
                    },
                    type: GUI.TableViewV2.CellType.Special.VOLUME_OVERLAY_CELL
                }]
            }];
        }

        _groupOnlyIncludesDynamicGroup(grouping) {
            return !grouping.map(function (groupObj) {
                return AudioserverComp.getControlByPlayerId(groupObj.playerid);
            }).reduce(function (sum, right) {
                return sum && !right.isControlPartOfFixedGroup(this.control);
            }.bind(this), true);
        }

        _getSyncedControls() {
            Debug.Media.DynamicGroups && console.log(this.viewId, "_getSyncedControls");

            if (this._detectIsInDynamicGroup()) {
                // getGroupOf will always return the dynamic group first if a player is part of two groups
                var dynamicGroup = this.control.audioserverComp.getGroupOfPlayer(this.control.details.playerid);
                var controls = [];
                dynamicGroup.players.forEach(player => {
                    // may be undefined if grouped control not visible to user.
                    controls.pushObject(this.control.audioserverComp.getControlByPlayerId(player.playerid));
                });
                Debug.Media.DynamicGroups && console.log(this.viewId, "   dynamic group!");
                return controls;
            } else {
                Debug.Media.DynamicGroups && console.log(this.viewId, "   fixed group!");
                return this.control.resolveFixedGroup();
            }
        }

        _detectIsInDynamicGroup() {
            return this.control.audioserverComp.supportsDynamicGroups() && this.control.audioserverComp.isPartOfDynamicGroup(this.control.details.playerid);
        }

        _shouldShowGroupButton() {
            var res = this.control.audioserverComp.supportsDynamicGroups() && (this._detectIsInDynamicGroup() || this.control.isFixedGroup);
            Debug.Media.DynamicGroups && console.log(this.viewId, "_shouldShowGroupButton: " + !!res);
            Debug.Media.DynamicGroups && console.log(this.viewId, "    supportsDynGroups = " + this.control.audioserverComp.supportsDynamicGroups());
            Debug.Media.DynamicGroups && console.log(this.viewId, "    _isInDynamicGroup = " + this._detectIsInDynamicGroup());
            Debug.Media.DynamicGroups && console.log(this.viewId, "         isFixedGroup = " + this.control.isFixedGroup);
            return res;
        }

    }

    GUI.AudioZoneV2GroupVolumeView = AudioZoneV2GroupVolumeView;
    return GUI;
}(GUI || {});
