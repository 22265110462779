'use strict';

GUI.TableViewV2.CellType.Special.SPOTIFY_CELL = "AudioZoneV2SpotifyCell";

class AudioZoneV2SpotifyCell extends GUI.TableViewV2.Cells.GeneralCell {
    //region Getter
    get username() {
        if (this.control) {
            try {
                return Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.control).activeUserName;
            } catch (e) {
                return null;
            }
        } else {
            return null;
        }
    } //endregion Getter


    constructor() {
        super(...arguments);
        applyMixins(this, MediaStateHandlerV2.Mixin);
        this.contentType = this.EVENT_TYPES.SERVICE;
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.control = this.content.control; // Is required by the MediaStateHandlerV2
    }

    viewWillAppear() {
        this._registerForMediaServerReloadEvents(this.contentType);
        /*this.content.disclosureText = this.username;
        this.setDisclosureText(this.content.disclosureText);
         this.userButton = new GUI.LxButton(this, this.elements.disclosureLabel[0], Color.BUTTON_GLOW, null, true);
        this.userButton.useElementAsActivePart("color");
        this.userButton.setInactiveColor(this.content.disclosureColor);
        this.addToHandledSubviews(this.userButton);
        this.userButton.onButtonTapped = function() {
            Controls.AudioZoneV2Control.SingleTones.SpotifyAccountManager.shared(this.control).showAccountSelector(this.ViewController);
        }.bind(this)*/


        return super.viewWillAppear(...arguments);
    }

    viewWillDisappear() {
        this._unregisterForMediaServerEvents(this.contentType);

        super.viewWillDisappear(...arguments);
    }

    receivedServiceReloadEvent(reason) {
        /*this.content.disclosureText = this.username;
        this.setDisclosureText(this.content.disclosureText);*/
    }

    destroy() {
        this._unregisterForMediaServerEvents(this.contentType);

        super.destroy(...arguments);
    }

}

GUI.TableViewV2.Cells.AudioZoneV2SpotifyCell = AudioZoneV2SpotifyCell;
