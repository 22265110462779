'use strict';

class ControlAdditionalsScreen extends Controls.ControlContent {
    constructor() {
        super(...arguments);
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.element.addClass("control-additionals-screen");
        this.element.css("background-color", Color.APP_BACKGROUND); // some control contents set their own color, not good!

        this.titleBar = this._getTitlebar();
        this.prependSubview(this.titleBar);
        this.tableView = new GUI.TableViewV2(this, this);
        this.appendSubview(this.tableView);

        this._generateTableContent();

        this.tableView.reload();
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);

        this.titleBar.onLeftButtonTapped = function onLeftButtonTapped() {
            this.ViewController.navigateBack();
            this.titleBar.onLeftButtonTapped = null;
        }.bind(this);
    }

    viewDidDisappear() {
        this.titleBar.onLeftButtonTapped = null;
        super.viewDidDisappear(...arguments);
    }

    getAnimation() {
        return HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT;
    }

    getURL() {
        return "ControlAdditionals";
    }

    updateStructureChanges(oldControl, newControl) {
        super.updateStructureChanges(...arguments);

        this._generateTableContent();

        this.tableView.reload();
    }

    // Private methods
    _getTitlebar() {
        var titleBarConfig = {
            leftSide: TitleBarCfg.Button.BACK
        };
        var titleBar = new GUI.LxTitleBar(titleBarConfig);
        titleBar.setTitleBarSideTexts(this.control.name);
        return titleBar;
    }

    _generateTableContent() {
        this.table = [];
        var section0 = []; // STATISTIC

        if (!!this.control.statistic) {
            section0.push({
                content: {
                    leftIconSrc: "resources/Images/Controls/Statistic/chart.svg",
                    title: _("statistics"),
                    disclosureIcon: true
                },
                action: function () {
                    NavigationComp.showStatistic(this.control.uuidAction, this.control.statistic.outputs[0].uuid);
                }.bind(this)
            });
        } // EXPERT MODE


        if (ActiveMSComponent.isExpertModeEnabled()) {
            section0.push({
                content: {
                    leftIconSrc: "resources/Images/General/icon-expert-mode.svg",
                    leftIconColor: window.Styles.colors.red,
                    title: _("expert-settings"),
                    disclosureIcon: true
                },
                action: function () {
                    NavigationComp.showControlSettings(this.control);
                }.bind(this)
            });
        }

        this.table.push(section0); // SPECIFIC STUFF

        var section1 = []; // App/WebPage

        if (this.control.controlType === "Application") {
            section1.push({
                content: {
                    title: this.control.name,
                    disclosureIcon: true
                },
                action: this.control.open.bind(this.control)
            });
        }

        if (section1.length > 0) {
            this.table.push(section1);
        }
    }

    // TableViewDataSource Methods
    styleForTableView(tableView) {
        return HD_APP ? TableViewStyle.GROUPED : null;
    }

    numberOfSections(tableView) {
        return this.table.length;
    }

    numberOfRowsInSection(section, tableView) {
        return this.table[section].length;
    }

    cellTypeForCellAtIndex(section, row, tableView) {
        return GUI.TableViewV2.CellType.GENERAL;
    }

    contentForCell(cell, section, row, tableView) {
        return this.table[section][row].content;
    }

    // TableViewDelegate Methods
    didSelectCell(cell, section, row, tableView) {
        return this.table[section][row].action();
    }

}

Controls.ControlAdditionalsScreen = ControlAdditionalsScreen;
