'use strict';

/*window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.GATE = "GateControlStateCell";

    class GateControlStateCell extends GUI.TableViewV2.Cells.ControlStateCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        getStateIDs() {
            var stateIds = super.getStateIDs(...arguments);
            stateIds.push("isLocked");
            return stateIds;
        }

        receivedStates(states) {
            super.receivedStates(...arguments);

            if (states.isLocked) {
                super.setDetailedIcon(Icon.LOCK);
            }
        }

        // just override, so that the base class's call doesn't do anything
        setDisclosureText() {
        }

    }

    GUI.TableViewV2.Cells.GateControlStateCell = GateControlStateCell;
    return GUI;
}(window.GUI || {}); */
