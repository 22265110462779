'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneControl = Controls.AudioZoneControl || {};
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var commands;

        class AudioZoneControlVolumeControl extends GUI.View {
            //region Static
            static Template = function () {
                var getControlTemplate = function getContainer() {
                    return '' + '<div class="volume-control__decrease volume-control__item clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.VOL_MINUS, "item__icon") + '</div>' + '<div class="volume-control__slider-cntr"></div>' + '<div class="volume-control__increase volume-control__item clickable">' + ImageBox.getResourceImageWithClasses(Icon.AudioZone.VOL_PLUS, "item__icon") + '</div>';
                };

                return {
                    getControlTemplate: getControlTemplate
                };
            }(); //endregion Static

            /**
             *
             * @param control
             * @param isControlCell     weather or not it's shown in the control's cell.
             * @param disappearDelegate
             * @param element
             * @param [playerId]
             */
            constructor(control, isControlCell, disappearDelegate, element, playerId) {
                Debug.Control.AudioZone.VolumeControl && console.log("AudioZoneControlVolumeControl ctor");
                super(element ? element : $('<div />'));

                if (disappearDelegate) {
                    this.disappearDelegate = disappearDelegate;
                } else {
                    this.disappearDelegate = null;
                }

                this.control = control;
                this.playerId = playerId;
                this.element.addClass("audio-zone-control-volume-control");
                this.isControlCell = !!isControlCell;

                if (this.isControlCell) {
                    this.element.addClass("audio-zone-control-volume-control--cell");
                }

                this.eventHandlers = [];
                commands = MediaEnum.AudioCommands;
                this.swipeHandlers = [];
            }

            viewDidLoad() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "viewDidLoad");
                return Q(super.viewDidLoad(...arguments)).then(() => {
                    this.element.append(AudioZoneControlVolumeControl.Template.getControlTemplate(this.control));
                    this.elements = {}; // Control

                    this.elements.increaseButton = this.element.find('.volume-control__increase');
                    this.elements.sliderContainer = this.element.find('.volume-control__slider-cntr');
                    this.elements.decreaseButton = this.element.find('.volume-control__decrease'); // ---------------------
                    //    active controls
                    // ---------------------

                    this.buttons = {
                        increaseButton: new GUI.LxButton(this, this.elements.increaseButton[0], Color.BUTTON_GLOW, null, true, 250),
                        decreaseButton: new GUI.LxButton(this, this.elements.decreaseButton[0], Color.BUTTON_GLOW, null, true, 250)
                    };

                    return this.appendReactComp({
                        reactComp: GUI.LxSlider,
                        compProps: {
                            handleType: 'circle',
                            activeColor: this.isControlCell ? window.Styles.colors.stateActive : Color.AUDIO_ZONE_SLIDER_COLOR,
                            antiGhostTimer: true,
                            updateRate: 0.55, // lxSlider.js ~136
                            antiGhostDuration: 4000
                        },
                        target: this.elements.sliderContainer
                    }).then(instance => {
                        this.volumeSlider = instance;

                        this.buttons.increaseButton.useChildsAsActiveParts("fill");
                        this.buttons.decreaseButton.useChildsAsActiveParts("fill");
                        this.addToHandledSubviews(this.buttons.increaseButton);
                        this.addToHandledSubviews(this.buttons.decreaseButton);

                        return Q.resolve();
                    })
                });

            }

            viewWillAppear() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "viewWillAppear");
                return Q(super.viewWillAppear(...arguments)).then(() => {
                    var buttonKeys = Object.keys(this.buttons);

                    for (var i = 0; i < buttonKeys.length; i++) {
                        var btnKey = buttonKeys[i];
                        var button = this.buttons[btnKey];
                        button.onButtonTapped = this._handleButtonTapped.bind(this);
                        button.onButtonTicked = this._handleButtonTapped.bind(this);
                    }

                    this.volumeSlider.onPositionChanged = this._handleVolumeSlider.bind(this); // override swipe so when swiping on the volume bar, the tracks aren't changed.
                    // even though the slider should take care of this, we still handle the case where the slider isn't
                    // hit and the touch event would be passed on to the element.

                    var swipeOptions = {
                        swipeVelocityX: 0.1
                    };

                    SandboxComponent.registerForStateChangesForUUID(this.control.uuidAction, this, this.receivedStates);

                    if (this.event) {
                        this.receivedStates(this.event);
                    }
                });
            }

            viewDidAppear() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "viewDidAppear");
                super.viewDidAppear(...arguments);
            }

            viewWillDisappear() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "viewWillDisappear");
                SandboxComponent.unregisterForStateChangesForUUID(this.control.uuidAction, this);
                super.viewWillDisappear(...arguments);
            }

            viewDidDisappear(viewRemainsVisible) {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "viewDidDisappear");
                this.volumeSlider.onPositionChanged = null;
                var i;

                for (i = 0; i < this.swipeHandlers.length; i++) {
                    this.swipeHandlers[i].dispose();
                }

                var buttonKeys = Object.keys(this.buttons);

                for (i = 0; i < buttonKeys.length; i++) {
                    var btnKey = buttonKeys[i];
                    var button = this.buttons[btnKey];
                    button.onButtonTapped = null;
                    button.onButtonTicked = null;
                }

                super.viewDidDisappear(...arguments);
            }

            // --------------------------------------------------------
            // --------------------------------------------------------
            // Connection Handling
            // --------------------------------------------------------
            // --------------------------------------------------------

            /**
             * Decides weather to send the cmd to the music or miniserver
             * @param cmd
             * @param isDragging    if the cmd comes from a slider and it's currently dragging.
             */
            sendCommand(cmd, isDragging) {
                if (this.playerId) {
                    MediaServerComp.sendAudioZoneCommand(this.playerId, {
                        cmd: cmd
                    });
                } else {
                    this.control.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging, null, CmdSrc.CELL);
                }
            }

            // Handling events
            receivedStates(event) {
                this.event = cloneObjectDeep(event);

                var prevStep = this._volumeStep;
                var prevMax = this._maxValue;

                if (!event.hasOwnProperty('volumeStep')) {//status updates from the music server won't contain steps
                    //console.warn("Received an event with no volumeStep info: " + JSON.stringify(event));
                } else {
                    this._volumeStep = event.volumeStep;
                }

                if (!event.hasOwnProperty('maxVolume')) {//status updates from the music server won't contain max volume
                    //console.warn("Received an event with no maxVolume info: " + JSON.stringify(event));
                } else {
                    this._maxValue = event.maxVolume;
                }

                if (this._maxValue !== prevMax || this._volumeStep !== prevStep) {
                    // update volume slider props
                    this.volumeSlider.updateProperties({
                        max: this._maxValue,
                        step: this._volumeStep
                    });
                }

                if (event.hasOwnProperty(MediaEnum.Event.VOLUME)) {
                    // update volume
                    //console.log("volumeSlider setPosition", event[MediaEnum.Event.VOLUME]);
                    this._volume = event[MediaEnum.Event.VOLUME];
                    this.volumeSlider.setPosition(this._volume);
                }
            }

            // ------------------------------------------------------------------------------
            // ------------------------------------------------------------------------------
            //                UI Interaction
            // ------------------------------------------------------------------------------
            _handleButtonTapped(sourceBtn) {
                switch (sourceBtn) {
                    case this.buttons.increaseButton:
                        this._volumeUpButtonUsed();

                        break;

                    case this.buttons.decreaseButton:
                        this._volumeDownButtonUsed();

                        break;

                    default:
                        break;
                }
            }

            _volumeDownButtonUsed() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "_volumeDownButtonUsed");
                var cmd;

                if (Feature.MULTI_MUSIC_SERVER) {
                    // Only send Miniserver CMDS if the mediaServerComp isn't active!
                    if (Feature.VOLUME_STEP_SUPPORT) {
                        this._volume -= this._volumeStep;
                        cmd = Commands.AudioZone.VOLUME_STEP + "/" + this._volumeStep * -1;
                    } else {
                        this._volume -= this._volumeStep;
                        cmd = Commands.AudioZone.VOLUME_SET + "/" + this._volume;
                    }
                } else {
                    cmd = MediaEnum.AudioCommands.VOLUME.DOWN_BY + this._volumeStep;
                }

                this.sendCommand(cmd);

                this._cellInUse();
            }

            _volumeUpButtonUsed() {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "_volumeUpButtonUsed");
                var cmd;

                if (Feature.MULTI_MUSIC_SERVER) {
                    // Only send Miniserver CMDS if the mediaServerComp isn't active!
                    if (Feature.VOLUME_STEP_SUPPORT) {
                        this._volume += this._volumeStep;
                        cmd = Commands.AudioZone.VOLUME_STEP + "/" + this._volumeStep;
                    } else {
                        this._volume += this._volumeStep;
                        cmd = Commands.AudioZone.VOLUME_SET + "/" + this._volume;
                    }
                } else {
                    cmd = MediaEnum.AudioCommands.VOLUME.UP_BY + this._volumeStep;
                }

                this.sendCommand(cmd);

                this._cellInUse();
            }

            _handleVolumeSlider(volume, isOn, isDragging) {
                Debug.Control.AudioZone.VolumeControl && console.log(this.name, "_handleVolumeSlider:", volume);
                this.sendCommand(commands.VOLUME.SET_TO + volume, isDragging);

                this._cellInUse(isDragging);
            }

            // ------------------------------------------------------------------------------
            // By calling the delegate method, the disappear timeout will be extended in the volumeControlList
            _cellInUse(isDragging) {
                this.audioControlUsedDelegate && this.audioControlUsedDelegate.call(this, isDragging);
            }

            _ignoreSwipe(event) {
                // avoid handling the swipe in the view above
                event.stopPropagation();
            }

        }

        Controls.AudioZoneControl.AudioZoneControlVolumeControl = AudioZoneControlVolumeControl;
    }
    return Controls;
}(window.Controls || {});
