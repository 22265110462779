'use strict';

window.Controls = function (Controls) {
    class ControlContent extends GUI.Screen {
        constructor(details) {
            let control = null; // check if securedDetails are "required". If so, merge them onto this control.

            if (details.control.securedDetails) {
                var secDetails = ActiveMSComponent.getSecuredDetailsFor(details.control.uuidAction);
                control = ActiveMSComponent.mergeSecuredDetailsWithControl(details.control, secDetails);
            } else {
                control = details.control;
            }

            super($('<div/>'));
            Object.assign(this, StateHandler.Mixin);
            ["control-content", `${convertControlTypeToClassNameWithSuffix(control.controlType)}-control-content`, convertControlTypeToClassNameWithSuffix(this.constructor.name)].forEach(className => {
                this.element.addClass(className);
            });
            this.control = control;
            this.details = details;
        }

        viewDidLoad(...params) {
            var prms = super.viewDidLoad(...params); // title bar
            return this.control.prepareDataForContentScreen().then(() => {
                if (this.control.securedDetails) {
                    var secDetails = ActiveMSComponent.getSecuredDetailsFor(this.control.uuidAction);
                    this.control = ActiveMSComponent.mergeSecuredDetailsWithControl(this.control, secDetails);
                }

                if (this.getTitlebar) {
                    var titleBar = this.getTitlebar();

                    if (titleBar) {
                        this.titleBar = titleBar;
                        /*if (this.getURL() === this.control.uuidAction &&                    // only show on root view
                            (!this.control.uuidParent || !!this.control.parentControl)) {   // and on root controls OR if we have a parentControl (eg. Switch in LightControl)
                            this.titleBar.setExpertModeInfo(this.control);
                        }*/

                        this.prependSubview(this.titleBar);
                        this._leftTitleBarNameIsControlName = this.titleBar.getLeftTitleBarText() === this.control.name;

                        if (this.details.hasOwnProperty("titlebarColor")) {
                            this.titleBar.getElement().css("background-color", this.details.titlebarColor);
                        }

                        if (this.details.hasOwnProperty("titlebarTitle")) {
                            this.titleBar.setTitleBarSideTexts(this.details["titlebarTitle"]);
                        }
                    }
                }

                this._structureChangesReg = NavigationComp.registerForUIEvent(NavigationComp.UiEvents.StructureChanged, this._checkStructureChanges.bind(this));
                return prms;
            })
        }

        viewWillAppear() {
            var promises = [true]; // Request state updates before the subviews receive viewWillAppear in order to avoid issues
            // in the lifecycle methods if a subview is to be hidden/removed based on the states.
            // Lengthy tasks in received states should be performed async after viewDidAppear was called.

            promises.push(this._requestStates(null, this.getStateIDs()));

            if (this.ViewController.alert) {
                this._checkDismissTimeout();
            }

            promises.push(super.viewWillAppear(...arguments));
            this.element.toggleClass(convertControlTypeToClassNameWithSuffix(this.constructor.name) + "--hd", HD_APP); // TODO-woesto move to constructor where the other classes are added

            return Q.all(promises);
        }

        viewDidAppear() {
            var prms = super.viewDidAppear(...arguments);

            this._registerForStates(null, this._getStateIDs(), this.getStateIDsToIgnore()); // force a state update after the viewDidAppear, in some cases the states won't be updated
            // See issue: https://projects.zoho.eu/portal/loxone#buginfo/16571000000023018/16571000005712058


            this._requestStates(null, this._getStateIDs());

            return prms;
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        viewDidDisappear(viewRemainsVisible) {
            if (typeof viewRemainsVisible === "boolean" && viewRemainsVisible) {
                // the view's visible in the BG register again, so the states are shown.
                this._registerForStates(null, this._getStateIDs());
            }

            if (this.ViewController && this.ViewController.alert) {
                this._clearDismissTimeout();
            }

            return super.viewDidDisappear(...arguments);
        }

        destroy() {
            NavigationComp.removeFromUIEvent(this._structureChangesReg);
            this._structureChangesReg = null;

            this._unregisterStates(); // unregister again (we may have registered because of viewRemainsVisible in viewDidDisappear)


            this.titleBar = null;
            this.control = null;
            return super.destroy();
        }

        /**
         * return all state id's which are needed for the controlContent
         * @returns {[string,..]}
         */
        getStateIDs() {
            return null;
        }

        /**
         * alternative to getStateIds, this one will rule out updates for the IDS provided.
         * @returns {[string,..]}
         */
        getStateIDsToIgnore() {
            return null;
        }

        // ControlContent methods
        setBackgroundColor(color) {
            this.element.css("background-color", color);
        }

        /**
         * @see Control.sendCommand
         */
        sendCommand(cmd, type, cUuidAction, dr, argTexts, isAutomatic) {
            var promise = this.control.sendCommand.call(this.control, cmd, type, cUuidAction, dr, argTexts, CmdSrc.CONTENT, isAutomatic);
            promise.then(function success() {//console.log("successfully sent command: " , cmd);
            }, function error(e) {
                console.error(e);
            }, function notify(i) {
                console.info(i);
            });
            return promise;
        }

        getAnimation() {
            return HD_APP ? AnimationType.HD_OVERLAY : AnimationType.PUSH_OVERLAP_LEFT;
        }

        getURL() {
            return this.control.uuidAction;
        }

        getTitleBar() {
            console.log(this.name, "getTitleBar - ControlContentBase");
            return false;
        }

        /**
         * Will present the selected statistic output.
         * @param outputNumber - 0 if not given
         */
        showStatistic(outputNumber) {
            var output = this.control.statistic.outputs[outputNumber ? outputNumber : 0];
            this.ViewController.showStatistics(output);
        }

        dismissAlert() {
            if (!this.ViewController.alert) {
                console.error("can't dismiss alert because I'm not an alert!");
                return;
            }

            this.ViewController.dismissAlert();
        }

        updateStructureChanges() {
            // check if we should update the titleBar text..
            if (!this.details.hasOwnProperty("titlebarTitle") && this.titleBar && this._leftTitleBarNameIsControlName) {
                this.titleBar.setTitleBarSideTexts(this.control.name); // the this.control.name is already updated (by reference)
            }
        }

        receivedStates(states) {
            // this.shouldDismissAfterTimeout() may be state dependent
            // So update it accordingly
            if (this.ViewController.alert) {
                this._checkDismissTimeout();
            }
        }

        /**
         * AlertControlContent will be automatically dismissed after the duration
         * based on getDismissTimeoutDuration() if true
         * @returns {boolean}
         */
        shouldDismissAfterTimeout(states) {
            console.warn("Alert won't be dismissed automatically!");
            return false;
        }

        /**
         * Duration after which the AlertControlContent is automatically dismissed if shouldDismissAfterTimeout is true
         * @returns {number}
         */
        getDismissTimeoutDuration() {
            return 60 * 1000;
        }

        /**
         * return all state id's which are needed for the controlContent, wraps around getStateIDs
         * to ensure the messageCenterEntries state is always part of the states
         * @returns {[string,..]}
         */
        _getStateIDs() {
            var stateIds = this.getStateIDs();

            if (stateIds) {
                stateIds.pushIfNoDuplicate("messageCenterEntries");
            }

            return stateIds;
        }

        /**
         * Decide if the timeout (based on getDismissTimeoutDuration()) to automatically dismiss the AlertControlContent should be started
         * based on shouldDismissAfterTimeout
         * @private
         */
        _checkDismissTimeout() {
            var states = this.control.getStates();

            if (this.shouldDismissAfterTimeout(states)) {
                if (!this.dismissAlertTimeout) {
                    this.dismissAlertTimeout = setTimeout(this.dismissAlert.bind(this), this.getDismissTimeoutDuration());
                }
            } else {
                this._clearDismissTimeout();
            }
        }

        _clearDismissTimeout() {
            this.dismissAlertTimeout && clearTimeout(this.dismissAlertTimeout);
            this.dismissAlertTimeout = null;
        }

        _checkStructureChanges(event, changes) {
            if (changes.controls) {
                var concernsThisControl = !!changes.controls[this.control.uuidAction];

                if (!concernsThisControl && this.control.isGrouped()) {
                    // check if a sub-control was edited..
                    this.control.forEachSubControl(function (control) {
                        if (changes.controls[control.uuidAction]) {
                            concernsThisControl = true;
                        } else if (changes.controls[control.uuidAction] === null) {
                            concernsThisControl = true; // subcontrol was removed, must update the selection list
                            // TODO handle remove of this control!
                        }
                    });
                }

                if (changes.controls[this.control.uuidAction] === null) {
                    // use processWhenVisible, the user may have navigated back before...
                    this.processWhenVisible(function () {
                        this.ViewController.navigateBack(true);
                    }.bind(this));
                } else if (concernsThisControl) {
                    this.updateStructureChanges && this.updateStructureChanges();
                }
            }
        }

    }

    Controls.ControlContent = ControlContent;
    return Controls;
}(window.Controls || {});
