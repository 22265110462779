'use strict';

class TimedSwitchControlCard extends GUI.TableViewV2.Cells.ControlBaseCard {
    constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    /*,

            viewDidLoad: function viewDidLoad() {
                base.viewDidLoad.apply(this, arguments);

                // Wrike: 167063868
                this.buttonElement = this.element.find(".content__icon-placeholder");
                this.timerButton = new GUI.LxButton(this, this.buttonElement[0]);
                this.addToHandledSubviews(this.timerButton);
                this.timerButton.onButtonTapped = function() {
                    this.sendCommand(Commands.TIMED_SWITCH.PULSE);
                }.bind(this);
            }*/


}

GUI.TableViewV2.Cells.TimedSwitchControlCard = TimedSwitchControlCard;
