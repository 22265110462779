'use strict';
/*
grouping = List of AudioZones which are grouped
 */

GUI.TableViewV2.CellType.Special.ZONE_GROUPING = "ZoneGroupingCell";

class ZoneGroupingCell extends GUI.TableViewV2.Cells.BaseCell {
    constructor() {
        super(...arguments);
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        var table;
        table = '<table class="sub-cell-table">';
        this.content.grouping.forEach(function (zone, idx) {
            table += "<tr id='" + zone.playerid + "'>";
            table += "<td>" + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.IS_NOT_PLAYING, "play-state play-state--0") + "</td>";
            table += "<td>" + zone.name + "</td>";
            table += "<td>" + "   " + (idx === 0 ? ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED_BTN, "group-state") : "") + "</td>";
            table += "</tr>";
        }.bind(this));
        table += '</table>'; // Note: A proper tfoot element somehow ignores the padding, use a div instead

        table += "<div class='tfoot'></div>"; // The current playing title will be inserted here

        this.contentPlaceholder.append($(table));
        this.elements.groupState = this.contentPlaceholder.find(".group-state"); // Register after appending the table to the dom or else the elements are not yet available

        this.content.grouping.forEach(function (zone) {
            SandboxComponent.registerForStateChangesForUUID(zone.uuidAction, this, function (states) {
                this._zoneStatesReceived(states, zone.playerid);
            }.bind(this));
        }.bind(this));
    }

    destroy() {
        this.content.grouping.forEach(function (zone) {
            SandboxComponent.unregisterForStateChangesForUUID(zone.uuidAction, this);
        }.bind(this));
        return super.destroy(...arguments);
    }

    _zoneStatesReceived(states, playerId) {
        var affectedTr = this.element.find("tr#" + playerId),
            playStateContainer = affectedTr.find("td:first"),
            footer = this.element.find(".tfoot"),
            isPlaying = states.isPlaying;
        footer.text(states.stateTextShort);
        playStateContainer.html(ImageBox.getResourceImageWithClasses(isPlaying ? Icon.AudioZone.NEW.IS_PLAYING : Icon.AudioZone.NEW.IS_NOT_PLAYING, "play-state play-state--" + isPlaying));
        this.elements.groupState.css("fill", states.syncColor || Color.WHITE);
    }

}

GUI.TableViewV2.Cells.ZoneGroupingCell = ZoneGroupingCell;
