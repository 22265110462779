'use strict';
/*
grouping = List of AudioZones which are grouped
 */

GUI.TableViewV2.CellType.Special.ZONE_GROUPING_V2 = "ZoneGroupingCellV2";

class ZoneGroupingCellV2 extends GUI.TableViewV2.Cells.BaseCell {
    //region Static
    static Template = class {
        //region Static
        static groupingTable(content) {
            var table = '<table class="sub-cell-table">';
            content.grouping.forEach(function (zone, idx) {
                table += "<tr id='" + zone.details.playerid + "'>";
                table += "<td>" + ImageBox.getResourceImageWithClasses(Icon.AudioZone.NEW.IS_NOT_PLAYING, "play-state play-state--0") + "</td>";
                table += "<td>" + zone.getName(true) + "</td>";
                table += "<td>" + "   " + (idx === 0 ? ImageBox.getResourceImageWithClasses(Icon.AudioZone.GROUPED_BTN, "group-state") : "") + "</td>";
                table += "</tr>";
            }.bind(this));
            table += '</table>'; // Note: A proper tfoot element somehow ignores the padding, use a div instead

            table += this.groupFooter(); // The current playing title will be inserted here

            return table;
        }

        static groupFooter(states) {
            var footer = "<div class='tfoot'>" + "   " + "<div class='tfoot__cover-placeholder'></div>" + "   " + "<div class='tfoot__text-container'>" + "      " + "<div class='text-container__title'></div>" + "      " + "<div class='text-container__subtitle'></div>" + "   </div>" + "</div>";
            return footer;
        } //endregion Static


    }; //endregion Static

    constructor() {
        super(...arguments);
    }

    viewDidLoad() {
        super.viewDidLoad(...arguments);
        this.contentPlaceholder.append(ZoneGroupingCellV2.Template.groupingTable(this.content));
        this.elements.groupState = this.contentPlaceholder.find(".group-state");
        this.coverView = new Controls.AudioZoneV2Control.CurrentCoverViewV2(this.content.grouping[0]);
        this.appendSubview(this.coverView, this.contentPlaceholder.find(".tfoot__cover-placeholder")); // Register after appending the table to the dom or else the elements are not yet available

        this.content.grouping.forEach(function (zone) {
            SandboxComponent.registerForStateChangesForUUID(zone.uuidAction, this, function (states) {
                this._zoneStatesReceived(states, zone.details.playerid);
            }.bind(this));
        }.bind(this));
    }

    destroy() {
        this.content.grouping.forEach(function (zone) {
            SandboxComponent.unregisterForStateChangesForUUID(zone.uuidAction, this);
        }.bind(this));
        return super.destroy(...arguments);
    }

    _zoneStatesReceived(states, playerId) {
        var affectedTr = this.element.find("tr#" + playerId),
            tfooter = this.element.find(".tfoot"),
            playStateContainer = affectedTr.find("td:first"),
            isPlaying = states.isPlaying;
        playStateContainer.html(ImageBox.getResourceImageWithClasses(isPlaying ? Icon.AudioZone.NEW.IS_PLAYING : Icon.AudioZone.NEW.IS_NOT_PLAYING, "play-state play-state--" + isPlaying));
        this.elements.groupState.css("fill", states.syncColor || Color.WHITE);
        tfooter.find(".text-container__title").text(states.texts.mainText);
        tfooter.find(".text-container__subtitle").text(states.texts.subText);
        this.toggleSubview(this.coverView, states.title || states.station);
    }

}

GUI.TableViewV2.Cells.ZoneGroupingCellV2 = ZoneGroupingCellV2;
