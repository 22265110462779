'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.Special.MasterVolumeCell = "MasterVolumeCell";
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        class StateHandlerWrapper {
            constructor(control, stateIds, boundReceivedStates) {
                applyMixins(this, StateHandler.Mixin);
                this.control = control;
                this.boundReceivedStates = boundReceivedStates;

                this._registerForStates(this.control.uuidAction, stateIds);
            }

            destroy() {
                this._unregisterStates(this.control.uuidAction);
            }

            receivedStates(states) {
                this.boundReceivedStates.call(this, states, this.control.uuidAction);
            }
        }

        class MasterVolumeCell extends GUI.TableViewV2.Cells.VolumeOverlayCell {
            constructor() {
                super(...arguments);
                this.stateHandlerWrappers = [];
                this.stateMap = {};
            }

            setContent(content) {
                content.title = _("media.group.master");
                super.setContent(...arguments);
            }

            appendVolumeControl() {
                this.volumeControl = new Controls.AudioZoneV2Control.AudioZoneV2ControlMasterVolumeControl(this.content.controls[0]);
                this.appendSubview(this.volumeControl, this.contentPlaceholder);
            }

            getStateIDs() {
                return ["mastervolume", "isPlaying"];
            }

            registerStates() {
                this.stateHandlerWrappers = this.content.controls.map(function (control) {
                    return new StateHandlerWrapper(control, this.getStateIDs(), this.receivedStates.bind(this));
                }.bind(this));
            }

            unregisterStates() {
                this.stateHandlerWrappers.forEach(function (stateHandler) {
                    stateHandler.destroy();
                });
            }

            receivedStates(states, uuidAction) {
                var atLeastOnePlaying = false;
                this.stateMap[uuidAction] = states;
                Object.keys(this.stateMap).forEach(function (uuid) {
                    atLeastOnePlaying |= this.stateMap[uuid].isPlaying;
                }.bind(this));

                this._setIsPlaying(!!atLeastOnePlaying);
            }

            _onVolumeChange(cell, section, row, tableView, value) {
                this.control.audioserverComp.sendAudioZoneCommand(this.controls[0].details.playerid, {
                    cmd: MediaEnum.AudioCommands.MASTER_VOLUME.SET_TO + value,
                    type: Commands.Type.OVERRIDE
                });
            }

        }

        GUI.TableViewV2.Cells.MasterVolumeCell = MasterVolumeCell;
    }
    return GUI;
}(window.GUI || {});
