'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.AUDIO_ZONE_V2 = "AudioZoneV2ControlStateCell";

    class AudioZoneV2ControlStateCell extends GUI.TableViewV2.Cells.ControlStateCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        viewDidLoad() {
            return super.viewDidLoad(...arguments).then(function () {
                this.playPauseButton = new Controls.AudioZoneV2Control.PlayPauseButton(this.control);
                this.playPauseButton.element.addClass("content__play-pause-button");
                return this.appendSubview(this.playPauseButton, this.contentPlaceholder);
            }.bind(this));
        }

        getStateIDs() {
            var stateIds = super.getStateIDs(...arguments);
            stateIds.push("isSynced", "syncColor", "universalIsLocked", "canPlayMusic");
            return stateIds;
        }

        receivedStates(states) {
            // don't call the base, no need for it
            var showPlayPauseButton = true,
                disclosureText = "",
                disclosureTextColor = null,
                rightIcon = null,
                rightIconColor = null;

            if (states.hasOwnProperty('universalIsLocked') && states.universalIsLocked) {
                rightIcon = Icon.LOCK;
                rightIconColor = window.Styles.colors.red; // no text needed

                showPlayPauseButton = false;
            } else if (!this.control.isConfigured()) {
                disclosureText = _("unconfigured.title");
                disclosureTextColor = window.Styles.colors.orange;
                showPlayPauseButton = false;
            } else if (!states.canPlayMusic) {
                disclosureText = states.texts.connectivityText;
                disclosureTextColor = states.stateTextColor;
                showPlayPauseButton = false;
            } else if (states.isSynced) {
                rightIcon = Icon.AudioZone.GROUPED;
                rightIconColor = states.syncColor;
            }

            this.setRightIcon(rightIcon);
            this.setRightIconColor(rightIconColor);
            this.setDisclosureText(disclosureText);
            this.setDisclosureTextColor(disclosureTextColor);
            this.toggleSubview(this.playPauseButton, showPlayPauseButton);
        }

    }

    GUI.TableViewV2.Cells.AudioZoneV2ControlStateCell = AudioZoneV2ControlStateCell;
    return GUI;
}(window.GUI || {});
