'use strict';

GUI.TableViewV2.CellType.Special.INPUT_CELL = "AudioZoneV2InputCell";

class AudioZoneV2InputCell extends GUI.TableViewV2.Cells.GeneralCell {
    constructor() {
        super(...arguments);
        applyMixins(this, MediaStateHandlerV2.Mixin);
        this.contentTypes = [this.EVENT_TYPES.LINEIN, this.EVENT_TYPES.ZONE_FAVORITES];
    }

    viewWillAppear() {
        return super.viewWillAppear(...arguments).then(function () {
            this._registerForMediaServerEvents.apply(this, this.contentTypes);
        }.bind(this));
    }

    viewWillDisappear() {
        this._unregisterForMediaServerEvents.apply(this, this.contentTypes);

        return super.viewWillDisappear(...arguments);
    }

    receivedLineInAllDataEvent(data) {
        var filteredInputs = this.control.audioserverComp.filterCurrentInputs(data.items);

        if (this.hasPresetInput) {
            this._checkFav();
        } else if (filteredInputs.length > 0) {
            this.input = filteredInputs[0];
            this.setTitle(this.input.name);
            this.setLeftIcon(MusicServerEnum.LineInMap[this.input.icontype]);

            this._checkFav();
        } else {
            this.input = null;
            this.setTitle("-NO-INPUT-");
            this.setLeftIcon(Icon.DEFAULT);
            this.setEnabled(false);
        }
    }

    receivedZoneFavoritesReloadEvent() {
        this._checkFav();
    }

    receivedZoneFavoritesAllDataEvent() {
        this._checkFav();
    }

    _applyNewContent() {
        var promise = super._applyNewContent(...arguments);

        this.control && this._unregisterForMediaServerEvents.apply(this, this.contentTypes);
        this.control = this.content.control; // Is required by the MediaStateHandlerV2

        this._registerForMediaServerEvents.apply(this, this.contentTypes);

        this.hasPresetInput = !!this.content.input;

        if (this.hasPresetInput) {
            this._checkFav();

            this.input = this.content.input;
            this.setTitle(this.input.name);
            this.setSubtitle(this.input.uniqueSubtitle);
            this.setLeftIcon(MusicServerEnum.LineInMap[this.input.icontype]);
        }

        return promise;
    }

    _checkFav() {
        if (this.input && this.ViewController instanceof GUI.AddMediaViewControllerV2Base) {
            Controls.AudioZoneV2Control.SingleTones.FavoritesManager.shared(this.control).getExistingFavItem(this.input).then(function (existingFav) {
                this.setEnabled(!existingFav);
            }.bind(this));
        }
    }

    destroy() {
        this._unregisterForMediaServerEvents.apply(this, this.contentTypes);

        super.destroy(...arguments);
    }

}

GUI.TableViewV2.Cells.AudioZoneV2InputCell = AudioZoneV2InputCell;
