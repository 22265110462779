'use strict'; // This control is used in Light(V2)Control, don't delete it!

class SwitchControl extends Controls.ControlBase {
    //region Static
    static Template = function () {
        var getHeader = function getHeader() {
            return $('<div class="control-area__touch-area"></div>');
        };

        return {
            getHeader: getHeader
        };
    }(); //endregion Static

    constructor(delegate, dataSource, control, sectionIdx, rowIdx, tableView) {
        super(...arguments);
    }

    initHeader() {
        var headerContent = SwitchControl.Template.getHeader(),
            activeColor = this.control.details && this.control.details.activeColor || window.Styles.colors.stateActive;
        this.switch = new GUI.LxSwitch(this, headerContent[0], activeColor);
        return headerContent;
    }

    getStatusElements() {
        return {}; // return {} -> don't hide anything, but also don't disable the cell!
    }

    viewDidAppear() {
        super.viewDidAppear(...arguments);
        this.switch.onStateChanged = this._sendSwitchState.bind(this);
    }

    viewDidDisappear() {
        this.switch.onStateChanged = null;
        super.viewDidDisappear(...arguments);
    }

    destroy() {
        this.switch.destroy();
        super.destroy();
    }

    updateHeader(states) {
        this.switch.setActive(states.isActive);
    }

    // Private methods
    _sendSwitchState(state) {
        this.sendCommand(state ? Commands.SWITCH.ON : Commands.SWITCH.OFF);
    }

}

Controls.SwitchControl = SwitchControl;
