'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneControl = Controls.AudioZoneControl || {};
    Controls.AudioZoneControl.PlayPauseTheme = {
        A: "DarkOnBrightGround",
        B: "BrightOnDarkGround",
        MediaClient: "MediaClient",
        THIN: "ThinLines"
    };

    class PlayPauseButton extends GUI.View {
        //region Static
        static Template = function () {
            var getButtons = function getButtons(theme) {
                var playImage = Icon.AudioZone.PLAY,
                    pauseImage = Icon.AudioZone.PAUSE;

                switch (theme) {
                    case Controls.AudioZoneControl.PlayPauseTheme.A:
                        playImage = Icon.AudioZone.PLAY_FULL;
                        pauseImage = Icon.AudioZone.PAUSE_FULL;
                        break;

                    case Controls.AudioZoneControl.PlayPauseTheme.B:
                        playImage = Icon.AudioZone.PLAY_FULL_B;
                        pauseImage = Icon.AudioZone.PAUSE_FULL_B;
                        break;

                    case Controls.AudioZoneControl.PlayPauseTheme.MediaClient:
                        playImage = Icon.MediaClient.PLAY;
                        pauseImage = Icon.MediaClient.PAUSE;
                        break;
                }

                return ImageBox.getResourceImageWithClasses(playImage, "play-pause__play play-pause__icon") + ImageBox.getResourceImageWithClasses(pauseImage, "play-pause__pause play-pause__icon") + '<div class="play-pause__initializing play-pause__icon css-spinner-b"></div>';
            };

            return {
                getButtons: getButtons
            };
        }(); //endregion Static

        constructor(control, theme) {
            super($('<div class="audio-zone-play-pause-button clickable"></div>'));
            Object.assign(this, StateHandler.Mixin);
            this.control = control;
            this.theme = theme;

            switch (theme) {
                case Controls.AudioZoneControl.PlayPauseTheme.B:
                    this.element.addClass("audio-zone-play-pause-button--b");
                    break;

                case Controls.AudioZoneControl.PlayPauseTheme.THIN:
                    this.element.addClass("audio-zone-play-pause-button--thin");
                    break;

                case Controls.AudioZoneControl.PlayPauseTheme.MediaClient:
                    this.inControlCell = true;
                    this.element.addClass("audio-zone-play-pause-button--a");
                    break;

                default:
                    this.theme = Controls.AudioZoneControl.PlayPauseTheme.A;
                    this.inControlCell = true;
                    this.element.addClass("audio-zone-play-pause-button--a");
                    break;
            }

            this.states = {};
        }

        viewDidLoad() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewDidLoad");
            super.viewDidLoad(...arguments);
            this.element.append(PlayPauseButton.Template.getButtons(this.theme));
            this.elements = {}; // Control

            this.elements.playButtonIcon = this.element.find('.play-pause__play').hide();
            this.elements.pauseButtonIcon = this.element.find('.play-pause__pause').hide();
            this.elements.initializingIcon = this.element.find('.play-pause__initializing'); // ---------------------
            //    active controls
            // ---------------------

            this.buttons = {
                playPauseButton: new GUI.LxButton(this, this.element[0], Color.BUTTON_GLOW)
            };
            this.buttons.playPauseButton.useChildsAsActiveParts("fill");
            this.addToHandledSubviews(this.buttons.playPauseButton);
        }

        viewWillAppear() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewWillAppear");
            super.viewWillAppear(...arguments);

            this._registerForStates();

            this.buttons.playPauseButton.onButtonTapped = this._handlePlayPauseButton.bind(this);
        }

        viewDidAppear() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewDidAppear");
            super.viewDidAppear(...arguments);
        }

        viewWillDisappear() {
            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " viewWillDisappear");

            this._unregisterStates();

            super.viewWillDisappear(...arguments);
        }

        // --------------------------------------------------------
        // --------------------------------------------------------
        // State Handling
        // --------------------------------------------------------
        // --------------------------------------------------------
        receivedStates(states) {
            // PlayState
            if (this.element === null) {
                console.error("PlayPauseButton is still receiving updates, even tough it's no longer alive!");
                return;
            }

            Debug.Control.AudioZone.PlayPauseButton && console.log(this.name + " _receivedStates");
            this.states = states;

            if (this.states.serverReady && this.states.clientReady) {
                this.element.toggleClass('active', this.states.isPlaying);
                Debug.Control.AudioZone.PlayPauseButton && console.log("             server and client ready");

                if (this.states.isPlaying) {
                    this.elements.pauseButtonIcon.show();
                    this.elements.playButtonIcon.hide();
                    this.elements.initializingIcon.hide();
                } else {
                    this.elements.playButtonIcon.show();
                    this.elements.initializingIcon.hide();
                    this.elements.pauseButtonIcon.hide();
                }
            } else {
                this.element.toggleClass('active', false);
                var isInitializing = this.states.clientBooting || this.states.serverState === MediaEnum.ServerState.INITIALIZING;

                if (isInitializing) {
                    Debug.Control.AudioZone.PlayPauseButton && console.log("             intializing");
                    this.elements.initializingIcon.show();
                    this.elements.playButtonIcon.hide();
                } else {
                    Debug.Control.AudioZone.PlayPauseButton && console.log("             off");
                    Debug.Control.AudioZone.PlayPauseButton && console.log(this.states);
                    this.elements.playButtonIcon.show();
                    this.elements.initializingIcon.hide();
                }

                this.elements.pauseButtonIcon.hide();
            }
        }

        // --------------------------------------------------------
        // --------------------------------------------------------
        // Connection Handling
        // --------------------------------------------------------
        // --------------------------------------------------------
        sendMusicServerCommand(cmd) {
            MediaServerComp.sendAudioZoneCommand(this.control.details.playerid, {
                cmd: cmd
            }); // don't pass in a success-fn, no event command does require one.
        }

        sendMiniserverCommand(cmd) {
            var promise = SandboxComponent.sendCommand(this, this.control.uuidAction, cmd, null, this.control.isSecured);
            promise.then(function success() {//console.log("successfully sent command: " , cmd);
            }, function error(e) {
                console.error(e);
                this.receivedStates(this.states);
            }.bind(this), function notify(i) {
                console.info(i);
            });
            return promise;
        }

        // ------------------------------------------------------------------------------
        // ------------------------------------------------------------------------------
        //                UI Interaction
        // ------------------------------------------------------------------------------
        _handlePlayPauseButton() {
            if (!this.states) {
                return;
            }

            if (!this.states.serverReady) {
                if (Feature.AUDIO_ZONE__WAKE_AND_PLAY) {
                    // starts the server & sends play right after it is awake
                    this.sendMiniserverCommand(Commands.AudioZone.CLIENT.PLAY);
                } else {
                    this.sendMiniserverCommand(Commands.AudioZone.SERVER.ON);
                }
            } else if (this.states.clientReady) {
                if (Feature.MULTI_MUSIC_SERVER) {
                    if (this.states.isPlaying) {
                        this.sendMiniserverCommand(MediaEnum.AudioCommands.PAUSE);
                    } else {
                        this.sendMiniserverCommand(MediaEnum.AudioCommands.PLAY);
                    }
                } else {
                    if (this.states.isPlaying) {
                        this.sendMusicServerCommand(MediaEnum.AudioCommands.PAUSE);
                    } else {
                        this.sendMusicServerCommand(MediaEnum.AudioCommands.PLAY);
                    }
                }
            } else {
                this.sendMiniserverCommand(Commands.AudioZone.CLIENT.ON);
            }
        }

    }

    Controls.AudioZoneControl.PlayPauseButton = PlayPauseButton;
    return Controls;
}(window.Controls || {});
