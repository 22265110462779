'use strict';

GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell = "MediaBrowserBrowsableCell";

class MediaBrowserBrowsableCell extends GUI.TableViewV2.Cells.GeneralCell {
    constructor() {
        super(...arguments);
    }

    viewDidLoad() {
        var promise = super.viewDidLoad(...arguments);
        var iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(this.content.item).getIconObjForItem(this.content.item, this.content.details.identifier),
            coverViewPlaceholder = $("<div class='cover-view__placeholder'/>");
        this.contentPlaceholder.prepend(coverViewPlaceholder);
        this.coverView = new Controls.AudioZoneV2Control.CoverViewV2(coverViewPlaceholder, iconObj, true);
        this.addToHandledSubviews(this.coverView);
        return promise;
    }

    // ---------------------------------------------------------------------------------------------------------
    //   Methods for super fast tableContent updates
    // ---------------------------------------------------------------------------------------------------------
    cellTypeForReuse() {
        return GUI.TableViewV2.CellType.Special.MediaBrowserBrowsableCell;
    }

    updateContent(newContent) {
        return super._updateContent(...arguments);
    }

    /**
     * Will be called once the cell is being returned to the global cell cache. used to reset the cell to a
     * default content, or remove custom stuff applied by the content it had.
     * @returns {*}
     */
    resetCellContent() {
        return super.resetCellContent(...arguments).then(function () {
            this.coverView.empty();
        }.bind(this));
    }

    /**
     * This Method is used to apply a new content object onto the cell instance. e.g. update texts and so on.
     * @param newContent
     * @private
     */
    _applyNewContent(newContent) {
        var promises = [super._applyNewContent(...arguments)];
        var iconObj = Controls.AudioZoneV2Control.MediaBrowserV2Base.getConstructorForItem(newContent.item).getIconObjForItem(newContent.item, newContent.details.identifier);
        this.coverView.setIconObject(iconObj);
        return Q.all(promises);
    } // ---------------------------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------


}

GUI.TableViewV2.Cells.MediaBrowserBrowsableCell = MediaBrowserBrowsableCell;
