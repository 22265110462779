'use strict';
/*
 control                    // the audio zone control object - needed for the playPauseButton to register for states.
 */

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.CONTROL_STATE = "ControlStateCell";

    class ControlStateCell extends GUI.TableViewV2.Cells.GeneralCell {
        //region Static
        static Template = function () {
            var getDetailedIcon = function getDetailedIcon(iconSrc, iconClass) {
                iconClass = "placeholder__icon detailed-icon-placeholder__icon " + (iconClass ? iconClass : "");
                return '<div class="content__detailed-icon-placeholder">' + '   ' + ImageBox.getResourceImageWithClasses(iconSrc, iconClass) + '</div>';
            };

            return {
                getDetailedIcon: getDetailedIcon
            };
        }(); //endregion Static

        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
            Object.assign(this, StateHandler.Mixin);
        }

        setContent(content) {
            this.control = ActiveMSComponent.getControlByUUID(content.controlUuid);
            this.parentControl = ActiveMSComponent.getControlByUUID(content.parentControlUuid);
            content.title = this.control.name;
            var roomOfControl = this.control.getRoom();

            if (roomOfControl) {
                var foundControlInSameRoom = this.parentControl.hasControlInSameRoomAsControl(this.control),
                    foundControlInOtherRoom = this.parentControl.hasControlInOtherRoomAsControl(this.control);

                if (foundControlInSameRoom && foundControlInOtherRoom) {
                    content.subtitle = roomOfControl.name;
                } else if (!foundControlInSameRoom) {
                    content.title = roomOfControl.name;
                }
            }

            content.disclosureText = NBR_SPACE; // used to add the discText lbl in any way.. otherwise we couldn't call "setDisclosureText" in receivedStates!

            return super.setContent(...arguments);
        }

        viewWillAppear() {
            var promise = super.viewWillAppear(...arguments);

            this._registerForStates(null, this.getStateIDs());

            return promise;
        }

        viewWillDisappear() {
            this._unregisterStates();

            return super.viewWillDisappear(...arguments);
        }

        getStateIDs() {
            return ["stateTextShort", "stateTextColor", "stateIcon", "stateColor", "universalIsLocked"];
        }

        receivedStates(states) {
            if (this.__stateTextShort !== states.stateTextShort) {
                this.__stateTextShort = states.stateTextShort;
                this.setDisclosureText(states.stateTextShort);
            }

            if (this.__stateTextColor !== states.stateTextColor) {
                this.__stateTextColor = states.stateTextColor;
                this.setDisclosureTextColor(states.stateTextColor);
            }

            checkLegacyDrawingBug(); // On iOS 9 Devices the right side icons disappeared

            if (this.__stateIcon !== states.stateIcon) {
                this.__stateIcon = states.stateIcon;
                this.setRightIcon(states.stateIcon);
            }

            if (this.__stateColor !== states.stateColor) {
                this.__stateColor = states.stateColor;
                this.setRightIconColor(states.stateColor);
            }

            if (states.hasOwnProperty('universalIsLocked') && states.universalIsLocked) {
                this.__stateIcon = Icon.LOCK;
                this.__stateColor = window.Styles.colors.red; // no text needed

                this.__stateTextShort = "";
                this.setRightIcon(this.__stateIcon);
                this.setRightIconColor(this.__stateColor);
                this.setDisclosureText(this.__stateTextShort);
            }
        }

        /**
         * Set a custom detailed icon
         * @param iconSrc The source path of the resource
         * @param [iconClass]
         * @param [fillColor]
         */
        setDetailedIcon(iconSrc, iconClass, fillColor) {
            var actions = [],
                newIcon = $(ControlStateCell.Template.getDetailedIcon(iconSrc, iconClass)),
                prevIcon = this.elements.detailedIcon,
                insertAfterElem;

            if (prevIcon) {
                actions.push(prevIcon.replaceWith.bind(prevIcon, newIcon));
            } else {
                insertAfterElem = this.element.find(".content__disc-text");
                actions.push(newIcon.insertAfter.bind(newIcon, insertAfterElem));
            }

            if (fillColor) {
                actions.push(function () {
                    newIcon.children().first().css("fill", fillColor);
                });
            }

            return GUI.animationHandler.scheduleAll(actions).then(function () {
                this.elements.detailedIcon = newIcon;
            }.bind(this));
        }

    }

    GUI.TableViewV2.Cells.ControlStateCell = ControlStateCell;
    return GUI;
}(window.GUI || {});
