'use strict';

window.GUI = function (GUI) {
    GUI.TableViewV2.CellType.ALARM = "AlarmControlStateCell";

    class AlarmControlStateCell extends GUI.TableViewV2.Cells.ControlStateCell {
        constructor(delegate, dataSource, cellType, sectionIdx, rowIdx, tableView) {
            super(...arguments);
        }

        getStateIDs() {
            var stateIds = super.getStateIDs(...arguments);
            stateIds.push("disableMove", "stateColor");
            return stateIds;
        }

        receivedStates(states) {
            super.receivedStates(...arguments);

            if (states.alarmActive) {
                this.setRightIconColor(Color.RED);
            } else if (this.control.movementEnabled()) {
                var iconSrc;

                if (states.disableMove) {
                    iconSrc = Icon.Alarm.MOTION_INACTIVE;
                } else {
                    iconSrc = Icon.Alarm.MOTION_ACTIVE;
                }

                super.setDetailedIcon(iconSrc, null, states.stateColor);
            }
        }

        // just override, so that the base class's call doesn't do anything
        setDisclosureText() {
        }

    }

    GUI.TableViewV2.Cells.AlarmControlStateCell = AlarmControlStateCell;
    return GUI;
}(window.GUI || {});
