'use strict';

window.Controls = function (Controls) {
    Controls.AudioZoneControl = Controls.AudioZoneControl || {};

    class AudioZoneControlVolumeControlSlim extends Controls.AudioZoneControl.AudioZoneControlVolumeControl {
        constructor(control, isControlCell, disappearDelegate, element) {
            super(element ? element : $('<div class="audio-zone-control-volume-control-slim"/>'));
            this.control = control;
        }

        viewWillAppear() {
            super.viewWillAppear(...arguments);
            this.elements.increaseButton.remove();
            this.elements.decreaseButton[0].innerHTML = ImageBox.getResourceImageWithClasses(Icon.AudioZone.VOL_PLUS, 'item__icon');
        }

        sendCommand(cmd, isDragging) {
            console.log('isDragging: ' + isDragging);
            this.control.sendCommand(cmd, Commands.Type.OVERRIDE, null, isDragging, null, CmdSrc.CELL);
        }

    }

    Controls.AudioZoneControl.AudioZoneControlVolumeControlSlim = AudioZoneControlVolumeControlSlim;
    return Controls;
}(window.Controls || {});
