'use strict';

var GUI = function (GUI) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var SelectionTypeId = {
            JOIN: "join",
            CREATE: "create",
            EDIT: "edit"
        };

        class AudioZoneV2GroupingView extends GUI.View {
            //region Static
            static Template = class {
                static getOverlay() {
                    return $("<div class='audio-zone-v2-grouping-view'>" + "</div>");
                }
                static getHeader() {
                    return $("<div class='content__title-bar'>" + "   <div class='title-bar__text'></div>" + "</div>");
                }
                static getMainButton() {
                    return $("<div class='content__main-button'/>");
                }
            }; //endregion Static

            constructor(control, dismissFn, closeFn, forEco) {
                super(AudioZoneV2GroupingView.Template.getOverlay());
                applyMixins(this, StateHandler.Mixin);
                this.control = control;
                this.closeFn = closeFn;

                this.dismissFn = function () {
                    // ensure that when changing back to this view, it isn't scrolled to bottom.
                    this.tableView.getElement().scrollTop(0);
                    return dismissFn();
                }.bind(this);

                this.forEco = !!forEco;

                if (this.forEco) {
                    this.element.addClass("audio-zone-v2-grouping-view--for-eco");
                }

                this.updateAction = null;
            }

            getName() {
                return this.control.getName(true);
            }

            viewDidLoad() {
                return super.viewDidLoad(...arguments).then(function () {
                    this.elements = {
                        content: this.element,
                        mainButton: AudioZoneV2GroupingView.Template.getMainButton()
                    };
                    this.tableContent = this.getTableContent();
                    this.tableViewDataSource = tableViewDataSource(this.tableContent, null, this);
                    this.tableViewDelegate = tableViewDelegate(this.tableContent);
                    this.tableView = new GUI.TableViewV2(this.tableViewDataSource, this.tableViewDelegate);
                    this.appendSubview(this.tableView, this.elements.content);
                    GUI.animationHandler.append(this.elements.mainButton, this.elements.content);
                    this.mainButton = new GUI.LxButton(this, this.elements.mainButton[0]);
                    this.addToHandledSubviews(this.mainButton);

                    this._updateMainButton(!!this._isEditing);

                    this._tableReadyPromise = this.receivedStates(this.control.getStates());
                }.bind(this));
            }

            getStateIDs() {
                return [MusicServerEnum.Event.SYNCED_ZONES, MusicServerEnum.Event.MASTER_VOLUME, "isSynced", "syncColor", "syncedzones"];
            }

            viewWillAppear() {
                Debug.Media.DynamicGroups && console.log(this.viewId, "viewWillAppear");
                return Q.all([this._tableReadyPromise, super.viewWillAppear(...arguments)]).then(function (res) {
                    this._registerForStates(this.control.uuidAction, this.getStateIDs());

                    return res;
                }.bind(this));
            }

            viewDidAppear() {
                Debug.Media.DynamicGroups && console.log(this.viewId, "viewDidAppear");
                return super.viewDidAppear(...arguments).then(function () {
                    this._unregisterFromGroupFn = this.control.audioserverComp.registerForGroupingChanges(this._respondToGroupChanges.bind(this));
                }.bind(this));
            }

            viewWillDisappear() {
                Debug.Media.DynamicGroups && console.log(this.viewId, "viewWillDisappear");

                this._unregisterStates();

                this._unregisterFromGroupFn && this._unregisterFromGroupFn();
                this._unregisterFromGroupFn = null;
                return super.viewWillDisappear(...arguments);
            }

            receivedStates(states) {
                // Only reload the table if the grouping changed
                var prms;
                Debug.Media.DynamicGroups && console.log(this.viewId, "receivedStates: " + JSON.stringify(states.syncedzones));

                if (this._currentSyncedZones !== JSON.stringify(states.syncedzones)) {
                    Debug.Media.DynamicGroups && console.log(this.viewId, "   - zones did change!" + JSON.stringify(states.syncedzones));
                    Debug.Media.DynamicGroups && console.log(this.viewId, "                  was:" + JSON.stringify(this._currentSyncedZones));
                    prms = this._respondToGroupChanges();
                } else {
                    prms = Q.resolve();
                    Debug.Media.DynamicGroups && console.log(this.viewId, "   - zones unchanged! " + this._currentSyncedZones);
                }

                this._currentSyncedZones = JSON.stringify(states.syncedzones);
                return prms;
            }

            getTableContent() {
                var states = this.control.getStates();
                this._isEditing = states.isSynced && this.control.audioserverComp.isPartOfDynamicGroup(this.control.details.playerid);

                if (this._isEditing) {
                    Debug.Media.DynamicGroups && console.log(this.viewId, "getTableContent - isSynced - but not fixed!");
                    this.mainButton && this._updateMainButton(true);
                    return this._getEditGroupContent();
                } else {
                    Debug.Media.DynamicGroups && console.log(this.viewId, "getTableContent - NOT Synced!");
                    this.mainButton && this._updateMainButton(false);
                    return this._getJoinCreateContent();
                }
            }

            styleForTableView() {
                return HD_APP ? TableViewStyle.TRANSLUCENT : TableViewStyle.COMFORT_MODE_2020.replace("grouped", "translucent");
            }

            reload() {
                var delChanged, dsChanged;
                this.tableContent = this.getTableContent();
                dsChanged = this.tableViewDataSource.update(this.tableContent);
                delChanged = this.tableViewDelegate.update(this.tableContent);
                Debug.Media.DynamicGroups && console.log(this.viewId, "reload: " + (dsChanged || delChanged));
                return dsChanged || delChanged ? this.tableView.reload() : Q.resolve();
            }

            _respondToGroupChanges() {
                Debug.Media.DynamicGroups && console.log(this.viewId, "_respondToGroupChanges");
                this.joinList = this.control.audioserverComp.getJoinGroupList(this.control.details.playerid);
                this.createList = this.control.audioserverComp.getCreateGroupList(this.control.details.playerid);
                this.editList = this.control.audioserverComp.getEditGroupList(this.control.details.playerid);
                Debug.Media.DynamicGroups && console.log(this.viewId, "        joinList: ", this.joinList);
                Debug.Media.DynamicGroups && console.log(this.viewId, "      createList: ", this.createList);
                Debug.Media.DynamicGroups && console.log(this.viewId, "        editList: ", this.joinList);
                this.updateAction = null;
                return this.reload();
            }

            _updateMainButton(dissolve) {
                this.mainButton.onButtonTapped = this._handleDone.bind(this);
                GUI.animationHandler.schedule(function () {
                    this.elements.mainButton.text(_("done"));
                }.bind(this));
            }

            _getEditGroupContent() {
                if (!this.editList) {
                    return [];
                }

                var canDissolve = this._canDissolveGroup();

                return [{
                    headerTitle: _("audio-server.group.edit"),
                    sectionRightButtonTitle: _("media.group.dissolve"),
                    sectionRightButtonColor: canDissolve ? window.Styles.colors.red : window.Styles.colors.stateInActive,
                    rightSectionButtonTapped: canDissolve ? this._handleDissolve.bind(this) : null,
                    rows: this.editList.map(function (item) {
                        return this._getEditGroupItemContent(item);
                    }.bind(this))
                }];
            }

            _getJoinCreateContent() {
                var tableContent = [];

                if (this.joinList && this.joinList.length > 0) {
                    tableContent.push({
                        headerTitle: _("audio-server.join-group"),
                        rows: this.joinList.map(function (item) {
                            return this._getJoinGroupItemContent(item);
                        }.bind(this))
                    });
                }

                if (this.createList && this.createList.length > 0) {
                    tableContent.push({
                        headerTitle: _("media.group.set-up"),
                        rows: this.createList.map(function (item) {
                            return this._getCreateGroupItemContent(item);
                        }.bind(this))
                    });
                }

                return tableContent;
            }

            _getEditGroupItemContent(item) {
                var cellObj = this._createSelectableGroupItem(item);

                cellObj.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE;

                cellObj.didCheckCell = function (cell, section, row, tableView, selected) {
                    this.updateAction = SelectionTypeId.EDIT;
                    item.selected = selected;
                    Debug.Media.DynamicGroups && console.log(this.viewId, "editGroupItemSelectionChanged: " + item.name + " -> selected = " + !!selected);

                    this._ensureSelectionInDataSet(item, this.editList);
                }.bind(this);

                return cellObj;
            }

            _ensureSelectionInDataSet(item, dataSet, forceSelectCurrentPlayerItem) {
                dataSet.forEach(function (dsItem) {
                    // selection of tableView may become out of sync with the dataSet stored & used for sending.
                    if (this._isSameItem(dsItem, item) && dsItem.selected !== item.selected) {
                        Debug.Media.DynamicGroups && console.warn(this.viewId, "_ensureSelectionInDataSet: " + item.name + " selection no longer equals dataset, update!");
                        dsItem.selected = item.selected;
                    } else if (forceSelectCurrentPlayerItem && this._isCurrentPlayerItem(dsItem)) {
                        Debug.Media.DynamicGroups && console.warn(this.viewId, "_ensureSelectionInDataSet: current player item " + item.name + " --> force selection!");
                        dsItem.selected = true;
                    }
                }.bind(this));
            }

            _isSameItem(itemA, itemB) {
                return itemA._appId === itemB._appId;
            }

            _getCreateGroupItemContent(item) {
                var cellObj = this._createSelectableGroupItem(item, this._isCurrentPlayerItem(item));

                cellObj.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE;

                cellObj.didCheckCell = function (cell, section, row, tableView, selected) {
                    this.updateAction = SelectionTypeId.CREATE;
                    item.selected = selected;
                    Debug.Media.DynamicGroups && console.log(this.viewId, "createGroupItemSelectionChanged: " + item.name + " -> selected = " + !!selected); // in the create section, the current group must always be selected!

                    this._ensureSelectionInDataSet(item, this.createList, true);

                    this._resetJoinSelection();
                }.bind(this);

                return cellObj;
            }

            _getJoinGroupItemContent(item) {
                var cellObj = this._createSelectableGroupItem(item);

                cellObj.content.radioMode = GUI.TableViewV2.Cells.CheckableCell.RadioMode.SECTIONED;

                cellObj.didCheckCell = function (cell, section, row, tableView, selected) {
                    this.updateAction = SelectionTypeId.JOIN;
                    item.selected = selected;
                    Debug.Media.DynamicGroups && console.log(this.viewId, "joinGroupItemSelectionChanged: " + item.name + " -> selected = " + !!selected);

                    this._ensureSelectionInDataSet(item, this.joinList);

                    this._resetCreateSelection();
                }.bind(this);

                return cellObj;
            }

            _createSelectableGroupItem(item, locked) {
                var isGroup = item.hasOwnProperty("playerids") && item.playerids.length > 1;
                return {
                    type: isGroup ? GUI.TableViewV2.CellType.CHECKABLE_BUTTON : GUI.TableViewV2.CellType.CHECKABLE,
                    content: {
                        selected: !!item.selected,
                        title: item.name,
                        disabled: locked,
                        radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                        buttonSrc: Icon.INFO2
                    },
                    buttonTapped: this._showInfoPopupForGroup.bind(this, item, locked)
                };
            }

            _showInfoPopupForGroup(group, locked) {
                var nameMap = {},
                    roomName,
                    name,
                    names = [],
                    isDynamicGroup = this.control.audioserverComp.isDynamicGroup(group.group),
                    prefix = (isDynamicGroup ? _("audio-server.dynamic-group") : _("audio-server.fixed-group")) + "<br>"; // in order to avoid showing multiple zones with the same name, create a map and check it before setting
                // up the names list.

                group.controls.forEach(function (ctrl) {
                    name = ctrl.getName(false);
                    nameMap[name] = nameMap[name] || [];
                    nameMap[name].push(ctrl);
                });
                Object.keys(nameMap).forEach(playerName => {
                    if (nameMap[playerName].length === 1) {
                        names.push(playerName);
                    } else {
                        nameMap[playerName].forEach(ctrl => {
                            roomName = ctrl.getRoom() ? ctrl.getRoom().name : null;

                            if (roomName && playerName !== roomName) {
                                names.push(playerName + SEPARATOR_SYMBOL + roomName);
                            } else {
                                names.push(playerName); // no room assigned..
                            }
                        });
                    }
                });
                NavigationComp.showPopup({
                    title: group.name,
                    message: prefix + "- " + names.join("<br>- "),
                    buttonOk: true
                });
            }

            /**
             * In order to be allowed to dissolve a group, all members must be visible to the current user.
             * @returns {*}
             * @private
             */
            _canDissolveGroup() {
                return this._getUnpermittedPlayers().length === 0;
            }

            /**
             * Returns a list of all players that are part of the current players group, but aren't accessible.
             * @returns {*}
             * @private
             */
            _getUnpermittedPlayers() {
                var dynamicGroup = this.control.audioserverComp.getGroupOfPlayer(this.control.details.playerid);
                return dynamicGroup.players.filter(player => {
                    // may be undefined if grouped control not visible to user.
                    return !this.control.audioserverComp.getControlByPlayerId(player.playerid);
                });
            }

            _handleDone() {
                Debug.Media.DynamicGroups && console.log(this.viewId, "_handleDone: " + this.updateAction);
                var updatePromise = null,
                    selected,
                    groupId,
                    shouldClose = false;

                switch (this.updateAction) {
                    case SelectionTypeId.EDIT:
                        selected = this._getSelectedFromList(this.editList, true); // reuse groups the user isn't permitted to change.
                        shouldClose = selected.length === 1;
                        groupId = this.control.audioserverComp.getGroupOfPlayer(this.control.details.playerid).group;
                        updatePromise = this._sendUpdate(groupId, selected);
                        break;

                    case SelectionTypeId.JOIN:
                        selected = this._getSelectedFromList(this.joinList);
                        updatePromise = this._sendUpdate(selected[0].group, selected, this.control.details.playerid, false);
                        break;

                    case SelectionTypeId.CREATE:
                        selected = this._getSelectedFromList(this.createList);

                        if (selected.length < 2) {
                            Debug.Media.DynamicGroups && console.log(this.viewId, "   nothing to create, selected list too short: " + selected.length, selected);
                            updatePromise = Q.resolve(); // nothing to do, just resolve

                            shouldClose = true;
                        } else {
                            Debug.Media.DynamicGroups && console.log(this.viewId, "   send update, create group with " + selected.length + " players!", selected);
                            updatePromise = this._sendUpdate(selected[0].group, selected, this.control.details.playerid, true);
                        }

                        break;

                    default:
                        Debug.Media.DynamicGroups && console.warn(this.viewId, "_handleDone: NOTHING to do!");
                        updatePromise = Q.resolve();
                        shouldClose = !(this.control.getStates().isSynced || this.control.isFixedGroup);
                        break;
                }

                return updatePromise.then(function () {
                    return shouldClose ? this.closeFn() : this.dismissFn();
                }.bind(this));
            }

            _handleDissolve() {
                var groupId = this.control.audioserverComp.getGroupOfPlayer(this.control.details.playerid).group;
                return this._sendUpdate(groupId, []).then(function () {
                    return this.closeFn(); // close overlay when dissolved.
                }.bind(this));
            }

            _getSelectedFromList(list, keepUnpermittedPlayers) {
                Debug.Media.DynamicGroups && console.log(this.viewId, "_getSelectedFromList: " + list.length, list);

                const newList = list.filter(function (item) {
                    Debug.Media.DynamicGroups && console.log(this.viewId, "   is selected: " + item.name + " = " + !!item.selected);
                    return item.selected;
                }.bind(this));
                if (keepUnpermittedPlayers) {
                    // when the player is part of a group, but not all members are visible to the user - editing such
                    // a group (e.g. removing the player) should not modify the unpermitted players group membership.
                    // As they are not visible for selection, they need to be appended to the selected list.
                    return newList.concat(this._getUnpermittedPlayers().map(ogObj => {
                        let resObj = cloneObjectDeep(ogObj);
                        resObj.playerids = [ogObj.playerid];
                        return resObj;
                    }));
                } else {
                    return newList;
                }
            }

            _sendUpdate(groupId, list, mustHaveId, first) {
                var idParts = [],
                    playerIdString = "";

                if (first && mustHaveId && idParts.indexOf(mustHaveId) < 0) {
                    idParts.push(mustHaveId);
                }

                list.forEach(function (listItem) {
                    idParts.push(listItem.playerids.join(","));
                });

                if (!first && mustHaveId && idParts.indexOf(mustHaveId) < 0) {
                    idParts.push(mustHaveId);
                }

                playerIdString = idParts.join(",");
                Debug.Media.DynamicGroups && console.log(this.viewId, "_sendUpdate: " + groupId + ", players: " + playerIdString);
                return this.control.audioserverComp.updateGroup(groupId, playerIdString.split(","));
            }

            _resetJoinSelection() {
                this.joinList.forEach(function (item) {
                    item.selected = false;
                }.bind(this));
                this.reload();
            }

            _resetCreateSelection() {
                this.createList.forEach(function (item) {
                    item.selected = false;
                }.bind(this));
                this.reload();
            }

            _isCurrentPlayerItem(item) {
                var currPlayerId = this.control.details.playerid;
                return item.playerids.some(id => {
                    return "" + id === "" + currPlayerId;
                });
            }

        }

        GUI.AudioZoneV2GroupingView = AudioZoneV2GroupingView;
    }
    return GUI;
}(GUI || {});
